// src/Pages/ViewerStream.js
import React, { useEffect, useRef, useState } from 'react';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';

function ViewerStream({ streamId }) {
  const remoteVideoRef = useRef(null);
  const webRTCAdaptorRef = useRef(null);
  const [isStreaming, setIsStreaming] = useState(false);

  useEffect(() => {
    if (!streamId) return;

    webRTCAdaptorRef.current = new WebRTCAdaptor({
      websocket_url: "wss://onlybased.com:5443/LiveApp/websocket", // Replace with your Ant Media Server URL
      mediaConstraints: {
        video: false, // Viewers don't send video
        audio: false, // Viewers don't send audio
      },
      peerconnection_config: {
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
      },
      sdp_constraints: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
      },
      remoteVideoId: remoteVideoRef.current.id,
      debug: true,
      callback: function (info, obj) {
        if (info === "initialized") {
          webRTCAdaptorRef.current.play(streamId, "remoteVideo");
          setIsStreaming(true);
        } else if (info === "play_started") {
          console.log("Stream is now playing");
        } else if (info === "play_finished") {
          console.log("Stream stopped.");
          setIsStreaming(false);
        }
      },
      callbackError: function (error, message) {
        console.error("Error in streaming:", error, message);
        alert(`Error: ${message}`);
      },
    });

    return () => {
      if (webRTCAdaptorRef.current) {
        webRTCAdaptorRef.current.stop(streamId);
        setIsStreaming(false);
      }
    };
  }, [streamId]);

  return (
    <div>
      <h1>Live Stream</h1>
      <video
        id="remoteVideo"
        ref={remoteVideoRef}
        autoPlay
        controls
        style={{ width: '600px', height: '400px', marginTop: '20px' }}
      ></video>
      {!isStreaming && <p>No stream is currently available.</p>}
    </div>
  );
}

export default ViewerStream;