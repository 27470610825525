// RoleSelection.js

import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { RoleContext } from '../RoleContext';
import { useAddress } from "@thirdweb-dev/react";
import '../App.css';
import './RoleSelection.css'; // Import the new CSS file
import DustBunnyLogo from '../assets/svg/dock/dustbunny.svg'; // Import the DustBunny logo

function RoleSelection() {
  const navigate = useNavigate();
  const { setRole } = useContext(RoleContext);
  const walletAddress = useAddress();
  const [isAgeVerified, setIsAgeVerified] = useState(false);

  useEffect(() => {
    const ageVerified = localStorage.getItem("ageVerified");
    if (ageVerified === "true") {
      setIsAgeVerified(true);
    }
  }, []);

  const handleRoleSelection = async () => {
    if (!walletAddress) {
      console.error("Wallet address is required to select a role.");
      return;
    }

    setRole("viewer");

    try {
      const response = await axios.get(
        `https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/viewers`,
        {
          params: { wallet: walletAddress },
        }
      );
      response.status === 200 && response.data
        ? navigate("/viewer-dashboard")
        : navigate("/create-viewer-profile");
    } catch (error) {
      if (error.response?.status === 404) {
        navigate("/create-viewer-profile");
      } else {
        console.error("Error handling role selection:", error);
        alert("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const handleAgeConfirm = () => {
    localStorage.setItem("ageVerified", "true");
    setIsAgeVerified(true);
  };

  const handleAgeDecline = () => {
    window.location.href = "https://jes.us/"; // Redirect if underage
  };

  return (
    <div className="role-selection-container">
      {!isAgeVerified && (
        <div className="age-verification-modal">
          <h2 className="age-verification-title">Age Verification</h2>
          <p className="age-verification-text">
            This site contains adult content. By entering, you confirm that you are at least 18 years old.
          </p>
          <button onClick={handleAgeConfirm} className="age-verification-button">
            I am 18 or older
          </button>
          <button onClick={handleAgeDecline} className="age-verification-button">
            I am under 18
          </button>
        </div>
      )}

      {isAgeVerified && (
       <>
       <div className="role-selection-logo-container">
         <img
           src={DustBunnyLogo}
           alt="DustBunny Logo"
           className="role-selection-logo"
         />
       </div>
       <h1 className="role-selection-header">Login to DustBunny</h1>
       <p className="role-selection-subtext">Sign-In (Viewer).</p>
       <div className="role-selection-button-container">
         <button
           onClick={handleRoleSelection}
           className="role-selection-viewer-button"
           disabled={!walletAddress}
         >
           I'm a Degen
         </button>
       </div>
     </>
      )}
    </div>
  );
}

export default RoleSelection;