import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { RoleContext } from "../RoleContext";
import { useAddress } from "@thirdweb-dev/react";
import "../App.css";
import "./RoleSelection.css"; // Import the new CSS file
import DustBunnyLogo from "../assets/svg/dock/dustbunny.svg"; // Import the DustBunny logo

function Creator() {
  const navigate = useNavigate();
  const { setRole } = useContext(RoleContext);
  const walletAddress = useAddress();
  const [isAgeVerified, setIsAgeVerified] = useState(false);

  useEffect(() => {
    const ageVerified = localStorage.getItem("ageVerified");
    if (ageVerified === "true") {
      setIsAgeVerified(true);
    }
  }, []);

  const handleRoleSelection = async () => {
    if (!walletAddress) {
      console.error("Wallet address is required to select a role.");
      return;
    }

    setRole("Creator");

    try {
      const response = await axios.get(
        `https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators`,
        {
          params: { wallet: walletAddress },
        }
      );
      response.status === 200 && response.data
        ? navigate("/creator-dashboard")
        : navigate("/create-profile");
    } catch (error) {
      if (error.response?.status === 404) {
        navigate("/create-profile");
      } else {
        console.error("Error handling role selection:", error);
        alert("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const handleAgeConfirm = () => {
    localStorage.setItem("ageVerified", "true");
    setIsAgeVerified(true);
  };

  const handleAgeDecline = () => {
    window.location.href = "https://jes.us/"; // Redirect if underage
  };

  return (
    <div className="role-selection-container">
      {!isAgeVerified && (
        <div className="age-verification-modal">
          <h2 className="age-verification-title">Age Verification</h2>
          <p className="age-verification-text">
            This site contains adult content. By entering, you confirm that you
            are at least 18 years old.
          </p>
          <button onClick={handleAgeConfirm} className="age-verification-button">
            I am 18 or older
          </button>
          <button
            onClick={handleAgeDecline}
            className="age-verification-button"
          >
            I am under 18
          </button>
        </div>
      )}

      {isAgeVerified && (
        <div className="role-selection-main-content">
          <div className="instructions-box">
            <h2 className="instructions-title">How to Sign Up</h2>
            <ul className="instructions-list">
              <li>Download and Isntall MetaMask Wallet or similar.</li>
              <li>Connect your wallet to the platform.</li>
              <li>Click the "I'm a Bunny" button to proceed.</li>
              <li>If you're a new user, you'll be prompted to create a profile.</li>
              <li>Fill out your profile details and upload a profile picture.</li>
              <li>Submit the form to complete your account setup.</li>
              <li>If your device has a built in cam/microphone.</li>
              <li>Enter your stream title, description, and any tag.</li>
              <li>Then hit Start Stream.</li>
              <li>And that's it, you're live!</li>
            </ul>
          </div>
          <div className="role-selection-content">
            <div className="role-selection-logo-container">
              <img
                src={DustBunnyLogo}
                alt="DustBunny Logo"
                className="role-selection-logo"
              />
            </div>
            <h1 className="role-selection-header">Login to DustBunny</h1>
            <p className="role-selection-subtext">Sign-In (Creator).</p>
            <div className="role-selection-button-container">
              <button
                onClick={handleRoleSelection}
                className="role-selection-viewer-button"
                disabled={!walletAddress}
              >
                I'm a Bunny
              </button>
            </div>
          </div>
          <div className="updates-box">
            <h2 className="updates-title">Incoming Week 1 Platform Updates</h2>
            <ul className="updates-list">
              <li>Live streaming analytics enhancement</li>
              <li>Partner Accounts, Premium Streams, PPV Options</li>
              <li>Community leaderboard integration</li>
              <li>Custom NFT creation tools for creators</li>
              <li>Improved mobile experience</li>
              <li>First 100 creator accounts to email:</li>
              <li>b@nosecandy.io with their wallet address</li>
              <li>and stream for 1 hour a day for 3 days in a row.</li>
              <li>Will receive Free Partner Integration and Marketing Support.</li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Creator;