// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAddress, ConnectWallet } from "@thirdweb-dev/react";
import { RoleProvider } from './RoleContext';
import DesktopBackground from "./Components/ClappleOs/DesktopBackground";
import Dock from "./Components/ClappleOs/Dock";
import Taskbar from "./Components/ClappleOs/TaskBar";
import { ThemeProvider } from "./Components/ClappleOs/ThemeContext";
import Window from "./Components/ClappleOs/Window"; // Import the Window component
import Draggable from "react-draggable";

// Import pages/components
import Home from "./Pages/Home";
import RoleSelection from "./Pages/RoleSelection";
import CreatorDashboard from "./Pages/CreatorDashboard";
import ViewerDashboard from "./Pages/ViewerDashboard";
import CreateProfile from "./Pages/CreateProfile";
import ViewerProfile from "./Pages/ViewerProfile";
import CreateViewerProfile from './Pages/CreateViewerProfile';
import Admin from "./Pages/Admin";
import AdDisplay from "./Components/Advertiser/AdDisplay"; // Import the AdDisplay component
import Discover from "./Pages/Discover";
import Subscriptions from "./Pages/Subscriptions";
import ViewerStream from "./Pages/ViewerStream";
import UploadContent from "./Pages/UploadContent";
import ExploreCreators from "./Pages/ExploreCreators";
import CreatorProfile from "./Pages/CreatorProfile";
import AdvertiserDashboard from "./Pages/AdvertiserDashboard";
import MintNFTDrop from "./Pages/MintNFTDrop";
import WatchStream from "./Components/DustBunny/WatchStream";
import LiveStreams from "./Pages/LiveStreams";
import StakeNFT from "./Pages/StakeNFT";
import Creator from "./Pages/Creator";

import './App.css';

// Import ThirdwebProvider and ThirdwebStorage
import { ThirdwebProvider, ChainId } from "@thirdweb-dev/react";
import { ThirdwebStorage } from '@thirdweb-dev/storage';

function App() {
  const [walletAddress, setWalletAddress] = useState(null);
  const address = useAddress();

  // Window management state
  const [windows, setWindows] = useState({});
  const [highestZIndex, setHighestZIndex] = useState(1);

  useEffect(() => {
    if (address) {
      console.log(`Wallet address detected: ${address}`);
      setWalletAddress(address);
    } else {
      console.warn('No wallet address detected');
      setWalletAddress(null);
    }
  }, [address]);

  // Functions to manage windows
  const openWindow = (windowName) => {
    setHighestZIndex((prevZIndex) => prevZIndex + 1);
    setWindows((prevWindows) => ({
      ...prevWindows,
      [windowName]: {
        isOpen: true,
        isMinimized: false,
        zIndex: highestZIndex + 1,
      },
    }));
  };

  const closeWindow = (windowName) => {
    setWindows((prevWindows) => ({
      ...prevWindows,
      [windowName]: {
        ...prevWindows[windowName],
        isOpen: false,
      },
    }));
  };

  const minimizeWindow = (windowName) => {
    setWindows((prevWindows) => ({
      ...prevWindows,
      [windowName]: {
        ...prevWindows[windowName],
        isMinimized: !prevWindows[windowName].isMinimized,
      },
    }));
  };

  const bringToFront = (windowName) => {
    setHighestZIndex((prevZIndex) => prevZIndex + 1);
    setWindows((prevWindows) => ({
      ...prevWindows,
      [windowName]: {
        ...prevWindows[windowName],
        zIndex: highestZIndex + 1,
      },
    }));
  };

  return (
      <ThirdwebProvider
        desiredChainId={ChainId.Mainnet} // or your desired chain ID
        clientId="0eb54174b0ce8ca622bb43f63b7df333"    // Replace with your actual clientId
        storageInterface={
          new ThirdwebStorage({
            gatewayUrls: [
              'https://cloudflare-ipfs.com/ipfs/',
              'https://ipfs.io/ipfs/',
              'https://dweb.link/ipfs/',
            ],
          })
        }
      >
      <ThemeProvider>
        <DesktopBackground>
          <Taskbar walletAddress={walletAddress} />
          <RoleProvider>
            <Router>
              <div className="main-content">
                <AdDisplay />
                <div style={{ padding: "20px" }}>
            
                 {/* Render Windows */}
  <div id="windows-container" className="windows-container"></div>
{Object.entries(windows).map(([windowName, windowState]) => {
  if (!windowState.isOpen) return null;

  // Determine content based on windowName
  let windowContent;
  let windowTitle;

  switch (windowName) {
    case 'creator-dashboard':
      windowTitle = 'Creator Dashboard';
      windowContent = <CreatorDashboard walletAddress={walletAddress} />;
      break;
    case 'viewer-dashboard':
      windowTitle = 'Viewer Dashboard';
      windowContent = <ViewerDashboard walletAddress={walletAddress} />;
      break;
    case 'discover':
      windowTitle = 'Discover';
      windowContent = <Discover />;
      break;
    case 'subscriptions':
      windowTitle = 'Subscriptions';
      windowContent = <Subscriptions />;
      break;
    case 'upload-content':
      windowTitle = 'Upload Content';
      windowContent = <UploadContent />;
      break;
    case 'live-streams':
      windowTitle = 'Live Streams';
      windowContent = <LiveStreams />;
      break;
    case 'advertiser-dashboard':
      windowTitle = 'Advertiser Dashboard';
      windowContent = <AdvertiserDashboard />;
      break;
    case 'explore-creators':
      windowTitle = 'Explore Creators';
      windowContent = <ExploreCreators />;
      break;
    case 'mint-nft-drop':
      windowTitle = 'Mint NFT Drop';
      windowContent = <MintNFTDrop />;
      break;
    case 'watchstream':
      windowTitle = 'Watch Stream';
      windowContent = <WatchStream />;
      break;
    case 'whore-house':
      windowTitle = 'WhoreHouse';
      windowContent = <StakeNFT />;
      break;
    // Add more cases for other windows
    default:
      windowTitle = windowName;
      windowContent = (
        <div>
          <p>Content for {windowName}</p>
        </div>
      );
  }

  return (
    <Draggable
    // Set the draggable handle to the header
      key={windowName}
    >
      <div style={{ position: "relative", zIndex: 9997 }}>
        <Window
          title={windowTitle}
          onClose={() => closeWindow(windowName)}
          onMinimize={() => minimizeWindow(windowName)}
          isMinimized={windowState.isMinimized}
          onFocus={() => bringToFront(windowName)}
          zIndex={windowState.zIndex}
        >
          {windowContent}
        </Window>
      </div>
    </Draggable>
  );
})}
                  {/* Existing Routes */}
                  <Routes>
                    <Route
                      path="/"
                      element={<RoleSelection walletAddress={walletAddress} />}
                    />
                    <Route
                      path="/creator-dashboard"
                      element={<CreatorDashboard walletAddress={walletAddress} />}
                    />
                    <Route
                      path="/viewer-dashboard"
                      element={<ViewerDashboard walletAddress={walletAddress} />}
                    />
                    <Route
                      path="/create-viewer-profile"
                      element={<CreateViewerProfile walletAddress={walletAddress} />}
                    />
                    <Route
                      path="/create-profile"
                      element={<CreateProfile walletAddress={walletAddress} />}
                    />
                    <Route
                      path="/viewer-profile"
                      element={<ViewerProfile walletAddress={walletAddress} />}
                    />
                    <Route path="/Admin" element={<Admin />} />
                    <Route path="/discover" element={<Discover />} />
                    <Route path="/subscriptions" element={<Subscriptions />} />
                    <Route
                      path="/stream"
                      element={<ViewerStream walletAddress={walletAddress} />}
                    />
                    <Route path="/upload-content" element={<UploadContent />} />
                    <Route path="/explore-creators" element={<ExploreCreators />} />
                    <Route path="/creator/:walletAddress" element={<CreatorProfile />}
                    />
                    <Route
                      path="/advertiser-dashboard"
                      element={<AdvertiserDashboard />}
                    />
                    <Route path="/mint-nft-drop" element={<MintNFTDrop />} />
                    <Route path="/watchstream" element={<WatchStream />} />
                    <Route path="/live-streams" element={<LiveStreams />} />
                    <Route path="/whore-house" element={<StakeNFT />} />
                    <Route path="/creator" element={<Creator />} />
                  </Routes>
                </div>
              </div>
              {/* Dock at the bottom */}
              <Dock onDockItemClick={openWindow} />
            </Router>
          </RoleProvider>
        </DesktopBackground>
      </ThemeProvider>
    </ThirdwebProvider>
  );
}

export default App;