import React, { useEffect, useState } from "react";
import axios from "axios";
import TokenGate from "../Components/TokenGate";

function Discover() {
  const [newestCreators, setNewestCreators] = useState([]);
  const [topCreators, setTopCreators] = useState([]);

  useEffect(() => {
    const fetchCreators = async () => {
      try {
        // Fetch newest creators
        const newestResponse = await axios.get("https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators/newest");
        setNewestCreators(newestResponse.data);

        // Fetch top creators
        const topResponse = await axios.get("https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators/top");
        setTopCreators(topResponse.data);
      } catch (error) {
        console.error("Error fetching creators:", error.message);
      }
    };
    fetchCreators();
  }, []);

  return (
    <TokenGate>
    <div style={styles.container}>
      <h1 style={styles.header}>Discover</h1>
      <p style={styles.description}>Explore live streams and content from our newest and top creators.</p>

      <div style={styles.section}>
        <h2 style={styles.subHeader}>Newest Creators</h2>
        <div style={styles.creatorsGrid}>
          {newestCreators.map((creator) => (
            <div key={creator.wallet} style={styles.creatorCard}>
              {creator.profilePicture ? (
                <img
                  src={`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/${creator.profilePicture}`}
                  alt={creator.username}
                  style={styles.thumbnail}
                />
              ) : (
                <div style={styles.placeholderThumbnail}></div>
              )}
              <h3 style={styles.creatorName}>{creator.username}</h3>
            </div>
          ))}
        </div>
      </div>

      <div style={styles.section}>
        <h2 style={styles.subHeader}>Top Creators</h2>
        <div style={styles.creatorsGrid}>
          {topCreators.map((creator) => (
            <div key={creator.wallet} style={styles.creatorCard}>
              {creator.profilePicture ? (
                <img
                  src={`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/${creator.profilePicture}`}
                  alt={creator.username}
                  style={styles.thumbnail}
                />
              ) : (
                <div style={styles.placeholderThumbnail}></div>
              )}
              <h3 style={styles.creatorName}>{creator.username}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
    </TokenGate>
  );
}

const styles = {
  container: { padding: "20px", color: "#fff", fontFamily: "Arial, sans-serif" },
  header: { fontSize: "2.5em", marginBottom: "20px", textAlign: "center" },
  description: { fontSize: "1.2em", textAlign: "center", marginBottom: "20px" },
  section: { marginBottom: "40px" },
  subHeader: { fontSize: "2em", marginBottom: "20px", color: "#00ffcc" },
  creatorsGrid: { display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "center" },
  creatorCard: {
    width: "150px",
    padding: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    borderRadius: "8px",
    textAlign: "center",
    color: "#fff",
  },
  thumbnail: { width: "100%", height: "auto", borderRadius: "8px", objectFit: "cover" },
  placeholderThumbnail: {
    width: "100%",
    paddingTop: "100%", // Makes it a square
    backgroundColor: "#444",
    borderRadius: "8px",
  },
  creatorName: { marginTop: "10px" },
};

export default Discover;