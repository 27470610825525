// src/Pages/LiveStreams.js

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

function LiveStreams() {
  const [streams, setStreams] = useState([]);
  const playerRefs = useRef({}); // Keep track of player instances

  // Fetch the list of live streams
  useEffect(() => {
    const fetchStreams = async () => {
      try {
        const response = await axios.get('http://localhost:3001/api/streams');
        setStreams(response.data);
      } catch (error) {
        console.error('Error fetching live streams:', error);
      }
    };
    fetchStreams();

    // Clean up Video.js players when component unmounts
    return () => {
      Object.values(playerRefs.current).forEach((player) => {
        if (player) {
          player.dispose();
        }
      });
    };
  }, []);

  // Initialize Video.js players for each stream
  useEffect(() => {
    streams.forEach((stream) => {
      const videoElement = document.getElementById(
        `live-stream-${stream.streamName}`
      );
      if (videoElement && !playerRefs.current[stream.streamName]) {
        const player = videojs(videoElement);
        playerRefs.current[stream.streamName] = player;
      }
    });
  }, [streams]);

  return (
    <div>
      <h1>Live Streams</h1>
      {streams.length === 0 ? (
        <p>No live streams are currently available.</p>
      ) : (
        streams.map((stream) => (
          <div key={stream.streamName}>
            <h3>{stream.streamName}</h3>
            <video
              id={`live-stream-${stream.streamName}`}
              className="video-js vjs-default-skin"
              controls
              preload="auto"
              width="640"
              height="360"
              data-setup="{}"
            >
              <source
                src={`http://localhost:8000/live/${stream.streamName}/index.m3u8`}
                type="application/x-mpegURL"
              />
            </video>
          </div>
        ))
      )}
    </div>
  );
}

export default LiveStreams;