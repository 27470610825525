// src/Components/Admin.js
import React, { useState, useEffect } from "react";
import axios from "axios";

function Admin() {
  const [pendingCampaigns, setPendingCampaigns] = useState([]);
  const [adSlotId, setAdSlotId] = useState("");
  const [minimumCpc, setMinimumCpc] = useState("");

  useEffect(() => {
    const fetchPendingCampaigns = async () => {
      try {
        const response = await axios.get("https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/admin/campaigns/review");
        setPendingCampaigns(response.data.campaigns || []);
      } catch (error) {
        console.error("Error fetching campaigns:", error.message);
      }
    };

    fetchPendingCampaigns();
  }, []);

  const handleCampaignAction = async (campaignId, action) => {
    try {
      await axios.put("https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/admin/campaigns/review", { campaignId, status: action });
      setPendingCampaigns((prevCampaigns) => prevCampaigns.filter((campaign) => campaign._id !== campaignId));
    } catch (error) {
      console.error(`Error ${action} campaign:`, error.message);
    }
  };

  const handleUpdatePricing = async () => {
    try {
      await axios.put("https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/admin/pricing", { adSlotId, minimumCpc });
      alert("Minimum CPC updated successfully");
    } catch (error) {
      console.error("Error updating minimum CPC:", error.message);
    }
  };

  return (
    <div>
      <h1>Admin Panel</h1>

      <section>
        <h2>Pending Campaigns for Review</h2>
        {pendingCampaigns.length === 0 ? (
          <p>No campaigns awaiting review.</p>
        ) : (
          pendingCampaigns.map((campaign) => (
            <div key={campaign._id} className="campaign-item">
              <h4>{campaign.campaignName}</h4>
              <p>Advertiser: {campaign.advertiser}</p>
              <button onClick={() => handleCampaignAction(campaign._id, "approved")}>Approve</button>
              <button onClick={() => handleCampaignAction(campaign._id, "rejected")}>Reject</button>
            </div>
          ))
        )}
      </section>

      <section>
        <h2>Set Minimum CPC for Ad Slots</h2>
        <input
          type="text"
          value={adSlotId}
          onChange={(e) => setAdSlotId(e.target.value)}
          placeholder="Ad Slot ID"
        />
        <input
          type="number"
          value={minimumCpc}
          onChange={(e) => setMinimumCpc(e.target.value)}
          placeholder="Minimum CPC (in USDC)"
        />
        <button onClick={handleUpdatePricing}>Update Pricing</button>
      </section>
    </div>
  );
}

export default Admin;