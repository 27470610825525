// src/components/TokenGate.js

import React, { useEffect, useState } from "react";
import { useSDK, useAddress } from "@thirdweb-dev/react";
import { ethers } from "ethers";

const TokenGate = ({ children }) => {
  const sdk = useSDK();
  const userAddress = useAddress();
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(true);

  // Hardcoded token threshold and token contract address
  const requiredTokenAmount = ethers.utils.parseUnits("100", 1); // e.g., 100 COCAINE tokens
  const tokenContractAddress = "0x3595e426a7808e2482667ee4e453ef280fbb9cf4"; // COCAINE token address

  useEffect(() => {
    const checkTokenBalance = async () => {
      if (!userAddress) {
        console.log("No user address detected.");
        setLoading(false);
        return;
      }
      console.log("User Address:", userAddress);

      try {
        const contract = await sdk.getContract(tokenContractAddress, "token");
        console.log("Contract initialized:", contract);

        const balance = await contract.call("balanceOf", [userAddress]);
        console.log("Balance fetched:", balance.toString());
        console.log("Required Token Amount:", requiredTokenAmount.toString());

        setHasAccess(balance.gte(requiredTokenAmount)); // True if balance >= required amount
        console.log("Has access:", balance.gte(requiredTokenAmount));
      } catch (error) {
        console.error("Error checking token balance:", error);
      } finally {
        setLoading(false);
      }
    };

    checkTokenBalance();
  }, [sdk, userAddress]);

  // Render loading, access message, or gated content based on balance check
  if (loading) {
    return <p>Loading...</p>;
  }

  if (!hasAccess) {
    return (
      <div style={styles.accessDenied}>
        <p>You need at least 100 COCAINE tokens to access this content.</p>
      </div>
    );
  }

  return <>{children}</>; // Render children if access is granted
};

const styles = {
  accessDenied: {
    padding: "20px",
    backgroundColor: "#ffcccb",
    color: "#333",
    textAlign: "center",
    borderRadius: "8px",
  },
};

export default TokenGate;
