import React, { useState } from "react";
import NoseCandyPrice from "../NoseCandyPrice";
import HarryPotterObamaSonicPrice from "../HarryPotterObamaSonicPrice";
import { useSDK } from "@thirdweb-dev/react";
import { ethers } from "ethers";

const TipCalculatorAndSender = ({ creatorWalletAddress }) => {
  const [selectedToken, setSelectedToken] = useState("nosecandy");
  const [usdAmount, setUsdAmount] = useState(0);
  const [transactionInProgress, setTransactionInProgress] = useState(false);
  const sdk = useSDK();

  const noseCandyPrice = NoseCandyPrice();
  const harryPotterObamaSonicPrice = HarryPotterObamaSonicPrice();
  const currentPrice = selectedToken === "nosecandy" ? noseCandyPrice : harryPotterObamaSonicPrice;
  const tokenAmount = currentPrice ? (usdAmount / currentPrice).toFixed(6) : 0;

  const handleTokenChange = (e) => setSelectedToken(e.target.value);
  const handleUsdChange = (e) => setUsdAmount(e.target.value);

  const handleSendTip = async () => {
    if (!tokenAmount || isNaN(tokenAmount) || Number(tokenAmount) <= 0) {
      alert("Please enter a valid amount to send.");
      return;
    }

    setTransactionInProgress(true);

    try {
      const tokenContractAddress =
        selectedToken === "nosecandy"
          ? "0x3595e426a7808e2482667ee4e453ef280fbb9cf4"
          : "0xYourHarryPotterObamaSonicTokenAddress";
      const contract = await sdk.getContract(tokenContractAddress, "token");

      // Fetch the token decimals
      const decimals = await contract.call("decimals");

      // Convert tokenAmount to BigNumber using the fetched decimals
      const tokenAmountBigNumber = ethers.utils.parseUnits(tokenAmount.toString(), decimals);

      // Initiate the transfer to the creator's wallet
      await contract.call("transfer", [
        creatorWalletAddress,
        tokenAmountBigNumber,
      ]);

      alert("Tip sent successfully!");
    } catch (error) {
      console.error("Transaction failed:", error);
      alert("Failed to send tip. Please try again.");
    } finally {
      setTransactionInProgress(false);
    }
  };

  return (
    <div style={styles.container}>
      <h3>Select Token for Tipping</h3>
      <select
        value={selectedToken}
        onChange={handleTokenChange}
        style={styles.select}
      >
        <option value="nosecandy">NoseCandy</option>
        <option value="harrypotterobamasonic">HarryPotterObamaSonic10Inu</option>
      </select>

      <div style={styles.inputContainer}>
        <label>Enter USD Amount:</label>
        <input
          type="number"
          value={usdAmount}
          onChange={handleUsdChange}
          style={styles.input}
          placeholder="Enter USD amount"
        />
      </div>

      <p>
        Current Price:{" "}
        {currentPrice ? `$${currentPrice} USD` : "Loading..."}
      </p>
      <p>
        Equivalent Token Amount: {tokenAmount}{" "}
        {selectedToken === "nosecandy" ? "NoseCandy" : "HarryPotterObamaSonic10Inu"}
      </p>

      <button
        onClick={handleSendTip}
        style={styles.submitButton}
        disabled={transactionInProgress}
      >
        {transactionInProgress ? "Sending..." : "Send Tip"}
      </button>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "rgba(30, 30, 30, 0.9)",
    borderRadius: "12px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
    textAlign: "center",
    color: "white",
  },
  select: {
    marginBottom: "15px",
    padding: "8px",
    borderRadius: "8px",
    width: "100%",
  },
  inputContainer: {
    margin: "15px 0",
  },
  input: {
    width: "100%",
    padding: "8px",
    borderRadius: "8px",
    textAlign: "center",
  },
  submitButton: {
    padding: "10px 20px",
    backgroundColor: "#8a2be2",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    width: "100%",
  },
};

export default TipCalculatorAndSender;