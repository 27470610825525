import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Metrics = ({ campaignId }) => {
  const [metrics, setMetrics] = useState({
    impressions: 0,
    clicks: 0,
    ctr: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/api/campaigns/${campaignId}/metrics`);

        if (response.data && response.data.metrics) {
          setMetrics(response.data.metrics);
        } else {
          setMetrics({
            impressions: 0,
            clicks: 0,
            ctr: 0,
          });
        }
      } catch (error) {
        console.error('Error fetching metrics:', error.message);
        setError('Failed to fetch metrics.');
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [campaignId]);

  if (loading) {
    return <div>Loading metrics...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="metrics-display">
      <h3>Campaign Metrics</h3>
      <p>Impressions: {metrics.impressions || 0}</p>
      <p>Clicks: {metrics.clicks || 0}</p>
      <p>Click-Through Rate (CTR): {(metrics.ctr || 0).toFixed(2)}%</p>
    </div>
  );
};

export default Metrics;