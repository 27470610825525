// src/Pages/ExploreCreators.js
import React from "react";
import TokenGate from "../Components/TokenGate";

function ExploreCreators() {
  return (
    <TokenGate>
    <div>
      <h1>Explore Creators</h1>
      <p>Browse through creators and discover new content!</p>
      {/* Placeholder for list of creators */}
      <div>
        <p>Creator 1</p>
        <p>Creator 2</p>
        <p>Creator 3</p>
      </div>
    </div>
    </TokenGate>
  );
}

export default ExploreCreators;