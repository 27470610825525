import React, { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import EmojiPicker from 'emoji-picker-react';
import Draggable from 'react-draggable';

const socket = io('https://dushbunnybe-d9e7ead8a6f6.herokuapp.com'); // Ensure this matches your backend URL

function Chat({ creatorWallet, viewerWallet, isCreator }) {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [username, setUsername] = useState('Unknown User');
  const chatRef = useRef(null);

  const wallet = isCreator ? creatorWallet : viewerWallet;
  const room = creatorWallet; // The room is always based on the creator's wallet

  useEffect(() => {
    if (!wallet) {
      console.error('Wallet is not provided when joining the room.');
      return;
    }

    console.log(`Wallet in Chat component: ${wallet}`);
    socket.emit('join-room', room, wallet);

    socket.on('chat-history', (history) => {
      setChatHistory(history);
      scrollToBottom();
    });

    socket.on('chat-message', (data) => {
      setChatHistory((prev) => [...prev, data]);
      scrollToBottom();
    });

    socket.on('set-username', ({ wallet: socketWallet, username: setUser }) => {
      console.log(`Username set for wallet ${socketWallet}: ${setUser}`);
      if (socketWallet === wallet) {
        setUsername(setUser);
      }
    });

    socket.on('user-typing', ({ sender }) => {
      setIsTyping(sender !== wallet);
      setTimeout(() => setIsTyping(false), 3000);
    });

    return () => {
      socket.off('chat-message');
      socket.off('chat-history');
      socket.off('user-typing');
      socket.off('set-username');
    };
  }, [room, wallet]);

  const sendMessage = () => {
    if (message.trim()) {
      socket.emit('chat-message', {
        room,
        message,
        sender: wallet,
      });
      setMessage('');
    }
  };

  const handleTyping = () => {
    socket.emit('typing', room, wallet);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage((prev) => prev + emojiObject.emoji); // Ensure emoji character is accessed correctly
  };

  const toggleMinimize = () => {
    setMinimized(!minimized);
  };

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };

  return (
    <Draggable handle=".draggable-handle">
      <div style={styles.chatContainer}>
        <div className="draggable-handle" style={styles.header}>
          <span>Chat - {username}</span>
          <button onClick={toggleMinimize} style={styles.minimizeButton}>
            {minimized ? '🔼' : '🔽'}
          </button>
        </div>
        {!minimized && (
          <>
            <div ref={chatRef} style={styles.chatHistory}>
              {chatHistory.map((chat, index) => (
                <div key={index} style={styles.chatMessage}>
                  <strong>{chat.sender}:</strong> {chat.message}{' '}
                  <small>({chat.timestamp})</small>
                </div>
              ))}
              {isTyping && (
                <div style={styles.typingIndicator}>Someone is typing...</div>
              )}
            </div>
            {showEmojiPicker && (
              <div style={styles.emojiPicker}>
                <EmojiPicker
                  onEmojiClick={(emojiObject) => handleEmojiClick(emojiObject)} // Correctly handle the emoji click
                  theme="light"
                  emojiStyle="apple"
                  height="350px"
                  width="300px"
                  autoFocusSearch={true}
                />
              </div>
            )}
            <div style={styles.inputContainer}>
              <button
                onClick={() => setShowEmojiPicker((prev) => !prev)}
                style={styles.emojiButton}
              >
                😊
              </button>
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                onKeyUp={handleTyping}
                placeholder="Type a message..."
                style={styles.input}
              />
              <button onClick={sendMessage} style={styles.sendButton}>
                Send
              </button>
            </div>
          </>
        )}
      </div>
    </Draggable>
  );
}

const styles = {
  chatContainer: {
    width: '300px',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#f5f5f7',
    zIndex: 1000,
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    border: '1px solid #d1d1d6',
  },
  header: {
    backgroundColor: '#e0e0e6',
    color: '#333',
    padding: '10px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'move',
    borderBottom: '1px solid #d1d1d6',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    fontSize: '14px',
    fontWeight: '500',
  },
  chatHistory: {
    height: '220px',
    overflowY: 'auto',
    padding: '10px',
    backgroundColor: '#ffffff',
  },
  chatMessage: {
    marginBottom: '8px',
    padding: '6px 10px',
    borderRadius: '8px',
    backgroundColor: '#e6e6ea',
    color: '#333',
    maxWidth: '85%',
    fontSize: '14px',
    fontWeight: '400',
  },
  typingIndicator: {
    fontStyle: 'italic',
    color: '#888',
    fontSize: '13px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    borderTop: '1px solid #d1d1d6',
    backgroundColor: '#f0f0f5',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderRadius: '20px',
    border: '1px solid #c4c4c9',
    fontSize: '14px',
    marginRight: '8px',
    outline: 'none',
  },
  sendButton: {
    padding: '8px 14px',
    backgroundColor: '#007aff',
    color: 'white',
    border: 'none',
    borderRadius: '16px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
  },
  emojiButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: '18px',
    marginRight: '8px',
  },
  emojiPicker: {
    position: 'absolute',
    bottom: '60px',
    left: '10px',
    zIndex: '1000',
    boxShadow: '0 2px 12px rgba(0,0,0,0.2)',
    borderRadius: '8px',
  },
};

export default Chat;