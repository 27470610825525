import React from 'react';
import { Rnd } from 'react-rnd';
import './Window.css';

const Window = ({
  title,
  children,
  onClose,
  onMinimize,
  isMinimized,
  onFocus,
  zIndex,
}) => {
  return (
    <Rnd
      default={{
        x: 100,
        y: 100,
        width: 300,
        height: 800,
      }}
      minWidth={300}
      minHeight={200}
      bounds="window" // Restrict the window movement within the browser window
      dragHandleClassName="window-header" // Only the header is draggable
      onDragStart={(e) => {
        onFocus();
        e.stopPropagation(); // Prevent drag interference
      }}
      onDragStop={(e, data) => {
        console.log('Dragging stopped:', data); // Log position after drag
      }}
      onResizeStart={onFocus} // Bring window to focus when resizing starts
      enableUserSelectHack={true}
      style={{
        zIndex, // Maintain window stacking order
        position: 'absolute', // Required for Rnd to position elements properly
      }}
      enableResizing={{
        top: false,
        right: true,
        bottom: true,
        left: true,
        topRight: false,
        bottomRight: true,
        bottomLeft: false,
        topLeft: false,
      }}
    >
      <div
        className={`window ${isMinimized ? 'window-minimized' : ''}`}
        style={{ width: '100%', height: '100%' }}
        onClick={onFocus} // Bring window to focus on click
      >
        <div
          className="window-header"
          onMouseDown={(e) => {
            onFocus();
            e.stopPropagation(); // Ensure focus event triggers correctly
          }}
        >
          <div className="window-controls">
            <button
              className="close-btn"
              onClick={(e) => {
                e.stopPropagation(); // Prevent interference during dragging
                onClose();
              }}
              aria-label="Close Window"
            ></button>
            <button
              className="minimize-btn"
              onClick={(e) => {
                e.stopPropagation(); // Prevent interference during dragging
                onMinimize();
              }}
              aria-label="Minimize Window"
            ></button>
            <button
              className="maximize-btn"
              onClick={(e) => {
                e.stopPropagation();
                console.log('Maximize functionality placeholder'); // Add maximize logic here
              }}
              aria-label="Maximize Window"
            ></button>
          </div>
          <div className="window-title">{title}</div>
        </div>
        {!isMinimized && (
          <div className="window-content">
            {children}
          </div>
        )}
      </div>
    </Rnd>
  );
};

export default Window;