import React, { useState, useContext, useEffect } from "react";
import { ethers } from "ethers";
import { ConnectWallet } from "@thirdweb-dev/react";
import ThemeToggle from "./ThemeToggle";
import DustBunnyLogo from '../../assets/svg/dock/dustbunny.svg';
import { ThemeContext } from "./ThemeContext";
import "./TaskBar.css";

function Taskbar({ walletAddress }) {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [dateTime, setDateTime] = useState("");
  const [tokenPrice, setTokenPrice] = useState(null);
  const [ensName, setEnsName] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown menu state

  // Real-time clock update
  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const options = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      setDateTime(now.toLocaleString(undefined, options));
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Fetch token price
  useEffect(() => {
    const fetchTokenPrice = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=nose-candy&vs_currencies=usd');
        const data = await response.json();
        setTokenPrice(data['nose-candy']?.usd ?? null);
      } catch (error) {
        console.error("Failed to fetch token price:", error);
      }
    };

    fetchTokenPrice();
    const priceInterval = setInterval(fetchTokenPrice, 60000);

    return () => clearInterval(priceInterval);
  }, []);

  // Resolve ENS name for wallet address
  useEffect(() => {
    const resolveENS = async () => {
      if (walletAddress) {
        try {
          const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_INFURA_URL);
          const ens = await provider.lookupAddress(walletAddress);
          setEnsName(ens || null);
        } catch (error) {
          console.error("Error resolving ENS name:", error.message);
          setEnsName(null);
        }
      }
    };

    resolveENS();
  }, [walletAddress]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle dropdown menu visibility
  };

  return (
    <div className="taskbar">
      <div className="taskbar-left">
        <img
          src={DustBunnyLogo}
          alt="DustBunny Logo"
          className="app-logo"
          onClick={toggleDropdown}
        />
        {isDropdownOpen && (
          <div className="dropdown-menu">
            <a href="https://nosecandy.io" target="_blank" rel="noopener noreferrer">
              NoseCandy.io
            </a>
            <a href="https://dextools.io/app/en/ether/pair-explorer/0xcd7d2f768e80cdcdcc85e4d4717d305a24dc85e8" target="_blank" rel="noopener noreferrer">
              Dextools.io
            </a>
            <a href="https://www.coingecko.com/en/coins/nose-candy" target="_blank" rel="noopener noreferrer">
              CoinGecko
            </a>
            <a href="https://x.com/nosecandyio" target="_blank" rel="noopener noreferrer">
              X.com
            </a>
            <a href="https://app.uniswap.org/swap?chain=mainnet&inputCurrency=NATIVE&outputCurrency=0x3595e426a7808e2482667ee4e453ef280fbb9cf4" target="_blank" rel="noopener noreferrer">
              Uniswap
            </a>
          </div>
        )}
      </div>
      <div className="taskbar-center">
        <span className="date-time">{dateTime}</span>
      </div>
      <div className="taskbar-right">
        <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
        {walletAddress ? (
          <span className="wallet-address">{ensName || walletAddress}</span>
        ) : (
          <ConnectWallet className="connect-wallet-btn" />
        )}
        {tokenPrice !== null && (
          <span className="token-price">COCAINE: ${tokenPrice.toFixed(6)}</span>
        )}
        <button className="settings-btn">⚙️</button>
      </div>
    </div>
  );
}

export default Taskbar;