import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function UploadContent() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("file", file);

    try {
      await axios.post("https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/content", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Content uploaded successfully!");
      navigate("/creator-dashboard");
    } catch (error) {
      console.error("Error uploading content:", error);
      alert("Failed to upload content.");
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Upload New Content</h1>
      <form onSubmit={handleUpload} style={styles.form}>
        <label style={styles.label}>
          Content Title:
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            style={styles.input}
          />
        </label>
        <label style={styles.label}>
          Description:
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            style={styles.textarea}
          />
        </label>
        <label style={styles.label}>
          File:
          <input type="file" onChange={handleFileChange} required style={styles.fileInput} />
        </label>
        <button type="submit" style={styles.button}>Upload</button>
      </form>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderRadius: "15px",
    maxWidth: "600px",
    margin: "20px auto",
    backgroundColor: "rgba(20, 20, 20, 0.8)",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
    color: "white",
  },
  header: {
    fontSize: "2em",
    color: "#00ffcc",
    textAlign: "center",
    marginBottom: "20px",
    textShadow: "0 0 5px #00ffcc, 0 0 10px #00ffcc",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontSize: "1.1em",
    color: "#ddd",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    padding: "10px",
    marginBottom: "15px",
    borderRadius: "5px",
    border: "1px solid #444",
    backgroundColor: "#333",
    color: "white",
  },
  textarea: {
    width: "100%",
    padding: "10px",
    height: "100px",
    borderRadius: "5px",
    border: "1px solid #444",
    backgroundColor: "#333",
    color: "white",
    marginBottom: "15px",
  },
  fileInput: {
    color: "white",
    marginBottom: "15px",
  },
  button: {
    backgroundColor: "#8a2be2",
    color: "white",
    padding: "10px 20px",
    borderRadius: "8px",
    cursor: "pointer",
    border: "none",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
    transition: "transform 0.2s",
    alignSelf: "center",
  },
};

export default UploadContent;