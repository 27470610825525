import React, { useState, useEffect } from "react";
import axios from "axios";
import TokenGate from "../Components/TokenGate";

function ViewerProfile({ walletAddress }) {
  const [profile, setProfile] = useState({
    username: "",
    bio: "",
    profilePicture: "", // Add profilePicture field
    favoriteCreators: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/viewers/${walletAddress}`);
        if (response.data) {
          setProfile(response.data); // Ensure profilePicture is part of response data
        }
      } catch (error) {
        console.error("Error fetching viewer profile:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (walletAddress) {
      fetchProfile();
    }
  }, [walletAddress]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/viewers/${walletAddress}`, profile);
      console.log("Profile updated successfully.");
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  if (isLoading) return <p>Loading...</p>;

  return (
    <TokenGate>
      <div>
        <h1>Your Viewer Profile</h1>
        
        {/* Render profile picture if available */}
        {profile.profilePicture && (
          <img
            src={profile.profilePicture} // Directly use the Cloudinary URL as-is
            alt={`${profile.username}'s profile`}
            style={styles.profileImage}
          />
        )}
        
        <form onSubmit={handleSubmit}>
          <label>
            Username:
            <input
              type="text"
              name="username"
              value={profile.username || ""}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Bio:
            <textarea
              name="bio"
              value={profile.bio || ""}
              onChange={handleInputChange}
              required
            />
          </label>
          <button type="submit" style={styles.button}>Update Profile</button>
        </form>
      </div>
    </TokenGate>
  );
}

const styles = {
  button: {
    backgroundColor: "blue",
    color: "white",
    border: "none",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "10px",
  },
  profileImage: {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    objectFit: "cover",
    marginBottom: "15px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
  },
};

export default ViewerProfile;