import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AdDisplay = () => {
  const [ad, setAd] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);

  // Fetch the ad on component load
  useEffect(() => {
    const fetchAd = async () => {
      try {
        const response = await axios.get('https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/ads/display');
        if (response.data.success && response.data.ad) {
          setAd(response.data.ad);
        }
      } catch (error) {
        console.error('Error fetching ad:', error.message);
      }
    };

    fetchAd();
    const interval = setInterval(fetchAd, 600000); // Refresh ad every 15 seconds
    return () => clearInterval(interval);
  }, []);

  // Set a random position for the ad each time the component mounts
  useEffect(() => {
    const randomX = Math.floor(Math.random() * (window.innerWidth - 1200));
    const randomY = Math.floor(Math.random() * (window.innerHeight - 300));
    setPosition({ x: randomX, y: randomY });
  }, []);

  const handleMouseDown = () => {
    setDragging(true);
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const newX = e.clientX - 600; // Adjust to center the click within the ad
      const newY = e.clientY - 150; // Adjust to center the click within the ad
      setPosition({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleClose = () => {
    setAd(null); // Remove the ad
  };

  const handleAdClick = async () => {
    if (ad && ad._id) {
      try {
        const response = await axios.post('https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/ads/click', {
          adId: ad._id,
        });
        if (!response.data.success) {
          console.error('Error recording ad click:', response.data.message);
        }
      } catch (error) {
        console.error('Error recording ad click:', error.message);
      }
    }
  };

  useEffect(() => {
    if (dragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging]);

  return (
    ad && (
      <div
        className="ad-display"
        onMouseDown={handleMouseDown}
        style={{
          position: 'fixed', // Ensure the ad is rendered above all other components
          zIndex: 9999, // High z-index to keep it above everything else
          left: position.x,
          top: position.y,
          cursor: 'grab',
          width: '1200px',
          height: '300px',
          borderRadius: '5px',
          marginBottom: '10px',
          overflow: 'hidden',
          backgroundColor: 'transparent',
        }}
      >
        {/* Close Button */}
        <button
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: '10px',
            left: '50%',
            transform: 'translateX(-50%)', // Center the button horizontally
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: '#fff',
            border: 'none',
            borderRadius: '3px',
            cursor: 'pointer',
            padding: '5px 10px',
            zIndex: 10,
          }}
        >
          Close
        </button>

        {/* Ad Content */}
        {ad.adCreativeUrl ? (
          <a
            href={ad.targetUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleAdClick} // Track the click when ad is clicked
          >
            <img
              src={ad.adCreativeUrl}
              alt="Ad"
              style={{ width: '100%', height: '100%', borderRadius: '5px' }}
            />
          </a>
        ) : (
          <p>Loading ad...</p>
        )}
      </div>
    )
  );
};

export default AdDisplay;