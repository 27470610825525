// src/components/HarryPotterObamaSonicPrice.js

import React, { useState, useEffect } from "react";
import axios from "axios";

const HarryPotterObamaSonicPrice = () => {
  const [price, setPrice] = useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await axios.get(
          `https://api.coingecko.com/api/v3/simple/price?ids=harrypotterobamasonic10in&vs_currencies=usd`
        );
        setPrice(response.data.harrypotterobamasonic10in?.usd);
      } catch (error) {
        console.error("Error fetching HarryPotterObamaSonic price:", error);
        setPrice(null);
      }
    };
    fetchPrice();
  }, []);

  return price ? `${price}` : "Loading...";
};

export default HarryPotterObamaSonicPrice;