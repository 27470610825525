// src/Components/WatchStream.js
import React, { useState } from 'react';
import TipCalculator from './TipCalculator';
import TippingComponent from './TippingComponent';

const WatchStream = ({
  selectedStream,
  isStreamStarted,
  startStream,
  isMinimized,
  onMinimizeToggle,
  walletAddress,
}) => {
  const [tipDetails, setTipDetails] = useState({ tokenAmount: 0, usdAmount: 0 });

  return (
    <div className="stream-embed">
      <div className="window-header">
        <h3>Now Watching: {selectedStream}</h3>
        <button onClick={onMinimizeToggle}>
          {isMinimized ? 'Restore' : 'Minimize'}
        </button>
      </div>
      {!isMinimized && (
        <>
          {!isStreamStarted ? (
            <button onClick={startStream}>Start Stream</button>
          ) : (
            <>
              <iframe src={`https://onlybased.com/LiveApp/play.html?id=${selectedStream}`} allow="autoplay" allowFullScreen></iframe>
              <TipCalculator onCalculate={(details) => setTipDetails(details)} />
              <TippingComponent streamId={selectedStream} creatorWalletAddress={selectedStream} tokenAmount={tipDetails.tokenAmount} usdAmount={tipDetails.usdAmount} />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default WatchStream;