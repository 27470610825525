// src/Pages/Subscriptions.js
import React from 'react';

function Subscriptions() {
  return (
    <div>
      <h1>Subscribe</h1>
      <p>Explore live streams and content.</p>
    </div>
  );
}

export default Subscriptions;