const styles = {
    container: {
      maxWidth: "600px",
      margin: "20px auto",
      padding: "20px",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      color: "white",
      borderRadius: "15px",
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
    },
    header: {
      fontSize: "2em",
      textAlign: "center",
      color: "#00ffcc",
      fontWeight: "bold",
      marginBottom: "20px",
      textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    label: {
      marginBottom: "15px",
      color: "#bbbbbb",
      fontSize: "1.1em",
    },
    input: {
      width: "100%",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #444",
      backgroundColor: "rgba(20, 20, 20, 0.8)",
      color: "#00ffcc",
      fontSize: "1em",
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.3)",
    },
    textarea: {
      width: "100%",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #444",
      backgroundColor: "rgba(20, 20, 20, 0.8)",
      color: "#00ffcc",
      fontSize: "1em",
      resize: "none",
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.3)",
    },
    button: {
      backgroundColor: "#8a2be2",
      color: "white",
      border: "none",
      padding: "10px",
      borderRadius: "5px",
      cursor: "pointer",
      marginTop: "20px",
      fontSize: "1em",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
    },
    image: {
      maxWidth: "150px",
      height: "auto",
      borderRadius: "5px",
      marginTop: "10px",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
    },
    error: {
      color: "red",
      fontSize: "0.9em",
    },
    previewText: {
      marginTop: "20px",
      fontSize: "1.1em",
      color: "#00ffcc",
      textAlign: "center",
    },
  };

    export default styles;