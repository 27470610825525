// src/components/NoseCandyPrice.js

import React, { useState, useEffect } from "react";
import axios from "axios";

const NoseCandyPrice = () => {
  const [price, setPrice] = useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await axios.get(
          `https://api.coingecko.com/api/v3/simple/price?ids=nose-candy&vs_currencies=usd`
        );
        setPrice(response.data["nose-candy"]?.usd);
      } catch (error) {
        console.error("Error fetching NoseCandy price:", error);
        setPrice(null);
      }
    };
    fetchPrice();
  }, []);

  return price;
};

export default NoseCandyPrice;