import React from "react";
import "./Dock.css";
import discoverIcon from "../../assets/svg/dock/discover.png";
import subscriptionsIcon from "..//..//assets/svg/dock/subscriptions.png";
import mintIcon from "../../assets/svg/dock/mint.png";
import whorehouseIcon from "../../assets/svg/dock/whorehouse.png";

function Dock({ onDockItemClick }) {
  const dockItems = [
    { windowName: "discover", icon: discoverIcon, label: "Discover" },
    { windowName: "subscriptions", icon: subscriptionsIcon, label: "Subscriptions" },
    { windowName: "mint-nft-drop", icon: mintIcon, label: "MintNFTDrop" },
    { windowName: "whore-house", icon: whorehouseIcon, label: "StakeNFT" },
  ];

  return (
    <nav className="dock">
      {dockItems.map((item) => (
        <button
          key={item.windowName}
          className="dock-item"
          onClick={() => onDockItemClick(item.windowName)}
        >
          <img src={item.icon} alt={item.label} />
        </button>
      ))}
    </nav>
  );
}

export default Dock;