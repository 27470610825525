import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./CreateProfileStyles"; // Use the provided styles

function CreateProfile({ walletAddress }) {
  const navigate = useNavigate();

  const [profile, setProfile] = useState({
    username: "",
    bio: "",
    interests: "",
    hobbies: "",
    age: "",
    contentType: "",
    profilePicture: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [profileExists, setProfileExists] = useState(false);
  const [usernameError, setUsernameError] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators`,
          { params: { wallet: walletAddress } }
        );
        if (response.data) {
          setProfile({
            ...response.data,
            interests: response.data.interests
              ? response.data.interests.join(", ")
              : "",
            hobbies: response.data.hobbies
              ? response.data.hobbies.join(", ")
              : "",
          });
          setProfileExists(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setProfileExists(false);
        } else {
          console.error("Error fetching creator profile:", error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (walletAddress) {
      fetchProfile();
    }
  }, [walletAddress]);

  const handleFileChange = (e) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      profilePicture: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("username", profile.username);
    formData.append("bio", profile.bio);
    formData.append("interests", profile.interests);
    formData.append("hobbies", profile.hobbies);
    formData.append("age", profile.age);
    formData.append("contentType", profile.contentType);
    formData.append("wallet", walletAddress);
    formData.append("role", "creator");
    if (profile.profilePicture) {
      formData.append("profilePicture", profile.profilePicture);
    }

    try {
      if (profileExists) {
        await axios.patch(
          `https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators/${encodeURIComponent(
            walletAddress
          )}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        alert("Profile updated successfully.");
        navigate("/creator-dashboard");
      } else {
        const usernameCheck = await axios.get(
          `https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators/check-username`,
          { params: { username: profile.username } }
        );

        if (usernameCheck.data.exists) {
          setUsernameError("Username already taken. Please choose another one.");
          return;
        }

        await axios.post(
          `https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        alert("Profile created successfully.");
        setProfileExists(true);
        navigate("/creator-dashboard");
      }
    } catch (error) {
      console.error("Error submitting profile:", error);
      alert(
        `Error submitting profile: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };

  if (isLoading) return <p>Loading...</p>;

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>
        {profileExists
          ? "Edit Your Creator Profile"
          : "Create Your Creator Profile"}
      </h1>
      <form onSubmit={handleSubmit} style={styles.form}>
        <label style={styles.label}>
          Username:
          <input
            type="text"
            name="username"
            value={profile.username || ""}
            onChange={(e) =>
              setProfile((prevProfile) => ({
                ...prevProfile,
                username: e.target.value,
              }))
            }
            required
            style={styles.input}
          />
          {usernameError && <p style={styles.error}>{usernameError}</p>}
        </label>
        <label style={styles.label}>
          Bio:
          <textarea
            name="bio"
            value={profile.bio || ""}
            onChange={(e) =>
              setProfile((prevProfile) => ({
                ...prevProfile,
                bio: e.target.value,
              }))
            }
            required
            style={styles.textarea}
          />
        </label>
        <label style={styles.label}>
          Interests (comma-separated):
          <input
            type="text"
            name="interests"
            value={profile.interests || ""}
            onChange={(e) =>
              setProfile((prevProfile) => ({
                ...prevProfile,
                interests: e.target.value,
              }))
            }
            style={styles.input}
          />
        </label>
        <label style={styles.label}>
          Hobbies (comma-separated):
          <input
            type="text"
            name="hobbies"
            value={profile.hobbies || ""}
            onChange={(e) =>
              setProfile((prevProfile) => ({
                ...prevProfile,
                hobbies: e.target.value,
              }))
            }
            style={styles.input}
          />
        </label>
        <label style={styles.label}>
          Age:
          <input
            type="number"
            name="age"
            value={profile.age || ""}
            onChange={(e) =>
              setProfile((prevProfile) => ({
                ...prevProfile,
                age: e.target.value,
              }))
            }
            style={styles.input}
          />
        </label>
        <label style={styles.label}>
          Content Type:
          <input
            type="text"
            name="contentType"
            value={profile.contentType || ""}
            onChange={(e) =>
              setProfile((prevProfile) => ({
                ...prevProfile,
                contentType: e.target.value,
              }))
            }
            style={styles.input}
          />
        </label>
        <label style={styles.label}>
          Profile Picture:
          <input
            type="file"
            name="profilePicture"
            onChange={handleFileChange}
            style={styles.input}
          />
        </label>
        <button type="submit" style={styles.button}>
          {profileExists ? "Update Profile" : "Create Profile"}
        </button>
      </form>
      {profile.profilePicture && typeof profile.profilePicture === "string" && (
        <div>
          <h3 style={styles.previewText}>Preview:</h3>
          <img
            src={profile.profilePicture}
            alt="Profile"
            style={styles.image}
          />
        </div>
      )}
    </div>
  );
}

export default CreateProfile;