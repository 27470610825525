import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Draggable from 'react-draggable';
import Chat from './Chat';
import TippingComponent from '../Components/DustBunny/TippingComponent';
import TipCalculator from '../Components/DustBunny/TipCalculator';
import DesktopIcons from '../Components/DesktopIcons';
import TokenGate from "../Components/TokenGate";
import styles from './ViewerDashboardStyles';

function ViewerDashboard({ walletAddress }) {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [liveStreams, setLiveStreams] = useState([]);
  const [favoriteCreators, setFavoriteCreators] = useState([]);
  const [creatorProfiles, setCreatorProfiles] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredStreams, setFilteredStreams] = useState([]);
  const [selectedStream, setSelectedStream] = useState(null);
  const [isStreamStarted, setIsStreamStarted] = useState(false);
  const [tipDetails, setTipDetails] = useState({ tokenAmount: 0, usdAmount: 0 });
  const [isTipWindowOpen, setIsTipWindowOpen] = useState(false);

  // Minimized states
  const [isProfileMinimized, setProfileMinimized] = useState(false);
  const [isStreamsMinimized, setStreamsMinimized] = useState(false);
  const [isWatchMinimized, setWatchMinimized] = useState(false);
  const [isChatMinimized, setChatMinimized] = useState(false);
  const [isCreatorsMinimized, setCreatorsMinimized] = useState(false);

  useEffect(() => {
    const fetchProfileAndStreams = async () => {
      try {
        const profileResponse = await axios.get('https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/viewers', {
          params: { wallet: walletAddress },
        });
        const profileData = profileResponse.data;
        setProfile({
          ...profileData,
          interests: profileData.interests || [],
          hobbies: profileData.hobbies || [],
        });

        const liveStreamsResponse = await axios.get('https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/streams/live');
        const liveStreamsData = liveStreamsResponse.data;

        const latestStreamsByWallet = {};
        liveStreamsData.forEach((stream) => {
          const wallet = stream.creatorWallet;
          if (!latestStreamsByWallet[wallet] || new Date(stream.startedAt) > new Date(latestStreamsByWallet[wallet].startedAt)) {
            latestStreamsByWallet[wallet] = stream;
          }
        });
        const latestStreamsArray = Object.values(latestStreamsByWallet);
        setLiveStreams(latestStreamsArray);

        const wallets = latestStreamsArray.map((stream) => stream.creatorWallet);
        if (wallets.length > 0) {
          const creatorProfilesResponse = await axios.post('https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators/details', {
            wallets,
          });
          const creatorProfilesData = creatorProfilesResponse.data.reduce((acc, creator) => {
            acc[creator.wallet] = creator;
            return acc;
          }, {});
          setCreatorProfiles(creatorProfilesData);
        }

        if (profileData.followedCreators && profileData.followedCreators.length > 0) {
          const creatorsResponse = await axios.post('https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators/status', {
            wallets: profileData.followedCreators,
          });
          setFavoriteCreators(creatorsResponse.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (walletAddress) {
      fetchProfileAndStreams();
    }
  }, [walletAddress]);

  useEffect(() => {
    const filtered = liveStreams.filter((stream) =>
      stream.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (stream.category && stream.category.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (stream.tags && stream.tags.some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase())))
    );
    setFilteredStreams(filtered);
  }, [searchQuery, liveStreams]);

  const handleWatchStream = (creatorWallet) => {
    setSelectedStream(creatorWallet);
    setIsStreamStarted(false);
  };

  const startStream = () => {
    setIsStreamStarted(true);
  };

  const handleFollowToggle = async (creatorWallet) => {
    try {
      const isFollowing = profile.followedCreators.includes(creatorWallet);
      if (isFollowing) {
        await axios.post('https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/viewers/unfollow', {
          viewerWallet: walletAddress,
          creatorWallet,
        });
      } else {
        await axios.post('https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/viewers/follow', {
          viewerWallet: walletAddress,
          creatorWallet,
        });
      }
      const updatedProfile = await axios.get('https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/viewers', {
        params: { wallet: walletAddress },
      });
      setProfile(updatedProfile.data);
    } catch (error) {
      console.error('Error updating follow status:', error);
    }
  };

  if (!profile) return <p style={styles.loadingText}>Loading...</p>;

  return (
    <TokenGate walletAddress={walletAddress} requiredToken="COCAINE" requiredAmount={1}>
      <div style={styles.container}>
      <Draggable>
  <div
    style={{
      ...styles.profileSection,
      ...(isProfileMinimized ? styles.profileSectionMinimized : {}),
    }}
  >
    <div style={styles.windowHeader}>
      <h1>Welcome, {profile.username}!</h1>
      <button onClick={() => setProfileMinimized(!isProfileMinimized)} style={styles.minimizeButton}>
        {isProfileMinimized ? 'Restore' : 'Minimize'}
      </button>
    </div>
    {!isProfileMinimized && (
      <>
    {profile.profilePicture && (
  <img
    src={profile.profilePicture} // Use the Cloudinary URL directly
    alt="Profile"
    style={styles.profileImage}
  />
)}
        <p style={styles.neonTitle}>{profile.bio}</p>
        <p style={styles.text}>Interests: {profile.interests.length > 0 ? profile.interests.join(', ') : 'None'}</p>
        <p style={styles.text}>Hobbies: {profile.hobbies.length > 0 ? profile.hobbies.join(', ') : 'None'}</p>
        <p style={styles.text}>Age: {profile.age || 'N/A'}</p>
        <button onClick={() => navigate('/create-viewer-profile')} style={styles.button}>
          Edit Profile
        </button>
      </>
    )}
  </div>
</Draggable>

        {/* Draggable Live Streams Section */}
        <Draggable>
          <div style={isStreamsMinimized ? styles.leftSidebarMinimized : styles.leftSidebar}>
            <div style={styles.windowHeader}>
              <h2 style={styles.subHeader}>Live Streams</h2>
              <button onClick={() => setStreamsMinimized(!isStreamsMinimized)} style={styles.minimizeButton}>
                {isStreamsMinimized ? 'Restore' : 'Minimize'}
              </button>
            </div>
            {!isStreamsMinimized && (
              <>
                <input
                  type="text"
                  placeholder="Search streams..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={styles.searchBar}
                />
                {filteredStreams.length > 0 ? (
                  <div style={styles.streamsList}>
                    {filteredStreams.map((stream) => {
                      const creator = creatorProfiles[stream.creatorWallet];
                      return (
                        <div key={stream._id} style={styles.streamCard}>
                          {creator && creator.profilePicture ? (
                            <img
                              src={`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/${creator.profilePicture}`}
                              alt={creator.username}
                              style={styles.thumbnail}
                            />
                          ) : (
                            <img
                              src="/default-avatar.png"
                              alt="Default Avatar"
                              style={styles.thumbnail}
                            />
                          )}
                          <h3 style={styles.streamTitle}>{stream.title}</h3>
                          <p style={styles.creatorText}>
                            By: {' '}
                            <span
                              onClick={() => navigate(`/creator/${stream.creatorWallet}`)}
                              style={styles.creatorLink}
                            >
                              {creator ? creator.username : 'Unknown Creator'}
                            </span>
                          </p>
                          <button onClick={() => handleWatchStream(stream.creatorWallet)} style={styles.button}>
                            Watch Now
                          </button>
                          <button
                            onClick={() => handleFollowToggle(stream.creatorWallet)}
                            style={styles.followButton}
                          >
                            {profile.followedCreators.includes(stream.creatorWallet) ? 'Unfollow' : 'Follow'}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p style={styles.noStreamsText}>No live streams currently available.</p>
                )}
              </>
            )}
          </div>
        </Draggable>

        {/* Draggable Favorite Creators Section */}
        <Draggable>
          <div style={isCreatorsMinimized ? styles.rightSidebarMinimized : styles.rightSidebar}>
            <div style={styles.windowHeader}>
              <h2>Your Favorite Creators</h2>
              <button onClick={() => setCreatorsMinimized(!isCreatorsMinimized)} style={styles.minimizeButton}>
                {isCreatorsMinimized ? 'Restore' : 'Minimize'}
              </button>
            </div>
            {!isCreatorsMinimized && (
              <div style={styles.creatorsList}>
                {favoriteCreators.map((creator) => (
                  <div key={creator.wallet} style={styles.creatorCard}>
                    <p
                      onClick={() => navigate(`/creator/${creator.wallet}`)}
                      style={styles.creatorLink}
                    >
                      {creator.username || creator.wallet}
                    </p>
                    <p style={styles.text}>Status: {creator.isOnline ? 'Online' : 'Offline'}</p>
                    <button
                      onClick={() => handleFollowToggle(creator.wallet)}
                      style={styles.followButton}
                    >
                      {profile.followedCreators.includes(creator.wallet) ? 'Unfollow' : 'Follow'}
                    </button>
                    {creator.isOnline && (
                      <button
                        onClick={() => handleWatchStream(creator.wallet)}
                        style={styles.button}
                      >
                        Watch Live
                      </button>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </Draggable>

       {/* Now Watching Section */}
{selectedStream && (
  <Draggable handle=".window-header">
    <div
      style={{
        ...styles.nowWatching,
        height: isWatchMinimized ? "40px" : "calc(100vh - 140px)", // Smaller height when minimized
        overflowY: isWatchMinimized ? "hidden" : "auto", // Disable scrolling when minimized
      }}
    >
      <div className="window-header" style={styles.windowHeader}>
        <h3 style={styles.streamTitle}>
          {isWatchMinimized
            ? "Now Watching (Minimized)"
            : `Now Watching: ${selectedStream}`}
        </h3>
        <button
          onClick={() => setWatchMinimized(!isWatchMinimized)}
          style={styles.minimizeButton}
        >
          {isWatchMinimized ? "Restore" : "Minimize"}
        </button>
      </div>
      {!isWatchMinimized && (
        <>
          {!isStreamStarted ? (
            <button
              onClick={() => {
                startStream();
                setIsTipWindowOpen(true); // Open Tip Window when stream starts
              }}
              style={styles.button}
            >
              Start Stream
            </button>
          ) : (
            <>
              <iframe
                src={`https://onlybased.com:5443/LiveApp/play.html?id=${selectedStream}`}
                style={styles.iframe}
                allow="autoplay"
                allowFullScreen
              ></iframe>
            </>
          )}
        </>
      )}
    </div>
  </Draggable>
)}
{/* Tip Window Section */}
{isTipWindowOpen && (
  <Draggable handle=".window-header">
    <div style={{ position: "absolute", zIndex: 1001 }}>
      <div
        className="window-header"
        style={{
          ...styles.windowHeader,
          cursor: "move",
          backgroundColor: "#f2f2f7",
          borderBottom: "1px solid #d1d1d6",
        }}
      >
        <h3 style={styles.streamTitle}>Tip Window</h3>
        <button
          onClick={() => setIsTipWindowOpen(false)}
          style={styles.minimizeButton}
        >
          Close
        </button>
      </div>
      <div style={{ padding: "20px", backgroundColor: "#ffffff", borderRadius: "12px" }}>
        <TippingComponent
          streamId={selectedStream}
          creatorWalletAddress={selectedStream}
          tokenAmount={tipDetails.tokenAmount}
          usdAmount={tipDetails.usdAmount}
        />
      </div>
    </div>
  </Draggable>
)}

 {/* Chat Section */}
{selectedStream && (
  <Draggable>
    <div>
      <Chat creatorWallet={selectedStream} viewerWallet={walletAddress} isCreator={false} />
    </div>
  </Draggable>
)}
      </div>
    </TokenGate>
  );
}

export default ViewerDashboard;