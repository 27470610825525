const styles = {
  dashboardContainer: {
    display: "flex", // Arrange sections side-by-side
    flexWrap: "wrap", // Allow wrapping if needed
    gap: "20px", // Space between sections
    padding: "20px", // Add padding to the container
    justifyContent: "flex-start", // Align items to the left
  },
  draggableWindow: {
    position: "relative", // Ensure draggable works as expected
  },
  profileSection: {
    width: '300px',
    height: 'auto',
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Glass-like effect
    backdropFilter: 'blur(10px)', // Blur effect
    borderRadius: '12px',
    padding: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    overflow: 'visible',
  },
  windowHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#f2f2f7',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    cursor: 'move', // Indicates draggable handle
  },
  profileImage: {
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    objectFit: 'cover',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
    border: '2px solid rgba(200, 200, 200, 0.5)',
    marginBottom: '15px',
  },
  profileTitle: {
    fontSize: '1.5em',
    color: '#007aff',
    fontWeight: '600',
    textShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px',
  },
  profileDetails: {
    fontSize: '1em',
    color: '#333',
    fontWeight: '500',
    marginBottom: '8px',
  },
  button: {
    backgroundColor: '#007aff',
    color: '#fff',
    padding: '10px 15px',
    borderRadius: '8px',
    cursor: 'pointer',
    border: 'none',
    fontWeight: '500',
    fontSize: '0.9em',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    marginTop: '10px',
    transition: 'background-color 0.2s',
  },
  buttonHover: {
    backgroundColor: '#005bb5',
  },
  minimizeButton: {
    backgroundColor: '#e0e0e0',
    color: '#555',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: '500',
    fontSize: '0.8em',
  },
  streamingControls: {
    padding: '15px',
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Glass effect
    backdropFilter: 'blur(12px)', // Subtle blur effect
    borderRadius: '12px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // MacOS-like shadow
    border: '1px solid rgba(200, 200, 200, 0.5)', // Subtle border for clarity
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '8px',
    border: '1px solid rgba(200, 200, 200, 0.5)',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
    color: '#333',
    fontSize: '1em',
  },
  textarea: {
    width: '100%',
    height: '80px',
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '8px',
    border: '1px solid rgba(200, 200, 200, 0.5)',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
    color: '#333',
    fontSize: '1em',
    resize: 'none',
  },
  video: {
    width: '100%',
    height: 'auto',
    marginTop: '10px',
    borderRadius: '12px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  analyticsSection: {
    padding: '15px',
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '12px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  neonTitle: {
    fontSize: '1.8em',
    color: '#007aff',
    fontWeight: '600',
    textShadow: `
      0 0 3px #007aff,
      0 0 6px #007aff,
      0 0 9px #007aff,
      0 0 12px #007aff
    `,
    marginBottom: '10px',
  },
  neonText: {
    fontSize: '1em',
    color: '#333',
    fontWeight: '500',
    textShadow: `
      0 0 3px #ddd,
      0 0 5px #ddd
    `,
    marginBottom: '10px',
  },
  contentSection: {
    padding: '15px',
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '12px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  contentItem: {
    padding: '10px',
    marginBottom: '15px',
    backgroundColor: 'rgba(250, 250, 250, 1)',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  contentVideo: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    marginTop: '10px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  contentImage: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    marginTop: '10px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  tippingSection: {
    padding: '15px',
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '12px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
};

export default styles;