import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import TokenGate from "../Components/TokenGate";

function CreatorProfile() {
  const { walletAddress } = useParams();
  const navigate = useNavigate();
  const [creator, setCreator] = useState(null);
  const [pastStreams, setPastStreams] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCreatorData = async () => {
      try {
        // Fetch creator profile
        const creatorResponse = await axios.get(
          "https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators",
          {
            params: { wallet: walletAddress },
          }
        );
        setCreator(creatorResponse.data);

        // Fetch creator's past streams
        const streamsResponse = await axios.get(
          `https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators/${walletAddress}/streams`
        );
        setPastStreams(streamsResponse.data);

        // Fetch creator's NFT collection
        const nftsResponse = await axios.get(
          `https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators/${walletAddress}/nfts`
        );
        setNfts(nftsResponse.data);
      } catch (error) {
        console.error("Error fetching creator data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCreatorData();
  }, [walletAddress]);

  const deleteStream = async (streamId) => {
    try {
      await axios.delete(
        `https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators/${walletAddress}/streams/${streamId}`
      );
      setPastStreams((prevStreams) =>
        prevStreams.filter((stream) => stream._id !== streamId)
      );
      alert("Stream deleted successfully.");
    } catch (error) {
      console.error("Error deleting stream:", error);
      alert("Failed to delete stream.");
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (!creator) return <p>Creator profile not found.</p>;

  return (
    <TokenGate>
      <div>
        <h1>{creator.username}'s Profile</h1>
        {creator.profilePicture && (
          <img
            src={creator.profilePicture} // Directly use the Cloudinary URL
            alt={`${creator.username}'s Profile`}
            style={{
              maxWidth: "200px",
              height: "auto",
              borderRadius: "5px",
            }}
          />
        )}
        <p>{creator.bio}</p>
        <p>
          Interests:{" "}
          {creator.interests.length > 0
            ? creator.interests.join(", ")
            : "None"}
        </p>
        <p>
          Hobbies:{" "}
          {creator.hobbies.length > 0 ? creator.hobbies.join(", ") : "None"}
        </p>

        {/* Past Streams Section */}
        <h2>Past Streams</h2>
        <div>
          {pastStreams.length > 0 ? (
            pastStreams.map((stream) => (
              <div key={stream._id}>
                <h3>{stream.title}</h3>
                <video
                  controls
                  src={stream.videoUrl}
                  style={{ maxWidth: "300px" }}
                />
                <button onClick={() => deleteStream(stream._id)}>
                  Delete Stream
                </button>
              </div>
            ))
          ) : (
            <p>No past streams available.</p>
          )}
        </div>

        {/* NFT Collection Section */}
        <h2>NFT Collection</h2>
        <div>
          {nfts.length > 0 ? (
            nfts.map((nft) => (
              <div key={nft._id}>
                <img
                  src={nft.imageUrl}
                  alt={nft.name}
                  style={{ maxWidth: "200px", borderRadius: "5px" }}
                />
                <p>{nft.name}</p>
                <p>{nft.description}</p>
              </div>
            ))
          ) : (
            <p>No NFTs available.</p>
          )}
        </div>
      </div>
    </TokenGate>
  );
}

export default CreatorProfile;