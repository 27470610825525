// src/Pages/CreatorDashboard.js
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";
import Chat from "./Chat";
import TippingComponent from "../Components/DustBunny/TippingComponent"; // Tipping component import
import TokenGate from "../Components/TokenGate"; // TokenGate import
import styles from "./CreatorDashboardStyles";

function CreatorDashboard({ walletAddress }) {
  const [contentList, setContentList] = useState([]);
  const [profile, setProfile] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [streamId, setStreamId] = useState(null);
  const [recordingId, setRecordingId] = useState(null);
  const [streamTitle, setStreamTitle] = useState("");
  const [streamcategory, setStreamcategory] = useState("");
  const [streamTags, setStreamTags] = useState(""); // State for tags
  const [analyticsData, setAnalyticsData] = useState({
    views: 0,
    followers: 0,
    tips: 0,
  });
  const [minimized, setMinimized] = useState({
    profile: false,
    stream: false,
    analytics: false,
    content: false,
  });
  const webRTCAdaptorRef = useRef(null);
  const localVideoRef = useRef(null);
  const navigate = useNavigate();

  const handleTipReceived = (amount) => {
    console.log(`Received tip: $${amount}`);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators?wallet=${walletAddress}`
        );
        setProfile(response.data);
      } catch (error) {
        console.error("Error fetching creator profile:", error);
      }
    };
    if (walletAddress) {
      fetchProfile();
    }
  }, [walletAddress]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get("https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/content");
        setContentList(response.data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };
    fetchContent();
  }, []);

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const response = await axios.get(`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/creators/analytics?wallet=${walletAddress}`);
        setAnalyticsData((prevData) => ({
          ...prevData,
          views: response.data.viewCount || 0,
          followers: response.data.followerCount || 0,
        }));
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };
    if (walletAddress) fetchAnalyticsData();
  }, [walletAddress]);

  const handleNewContent = () => navigate("/upload-content");
  const handleEditProfile = () => navigate("/create-profile");
  const handleViewEarnings = () => alert("Earnings: $1000");

  

  const startStreaming = () => {
    if (!localVideoRef.current) {
      console.error("localVideoRef is not set. Please ensure the video element is rendered correctly.");
      alert("Video element not found. Please try refreshing or restarting the stream.");
      return;
    }

    const streamIdentifier = walletAddress;
    setStreamId(streamIdentifier);

    webRTCAdaptorRef.current = new WebRTCAdaptor({
      websocket_url: "wss://onlybased.com:5443/LiveApp/websocket",
      mediaConstraints: {
        video: true,
        audio: true,
      },
      peerconnection_config: {
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
      },
      sdp_constraints: {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false,
      },
      localVideoId: localVideoRef.current.id,
      debug: true,
      callback: function (info, obj) {
        if (info === "initialized") {
          // Include creatorWallet explicitly
          const streamData = {
            creatorWallet: walletAddress, // Map walletAddress to creatorWallet here
            title: streamTitle,
            category: streamcategory,
            tags: streamTags.split(",").map(tag => tag.trim()), // Split tags into an array
          };
          axios.post(`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/streams/start`, {
            streamId: streamIdentifier,
            ...streamData,
          });
          webRTCAdaptorRef.current.publish(streamIdentifier, "webcam");
          setIsStreaming(true);
        } else if (info === "publish_started") {
          console.log("Stream started successfully");
        } else if (info === "publish_finished") {
          console.log("Stream stopped.");
          setIsStreaming(false);
        }
      },
      callbackError: function (error, message) {
        console.error("Error in streaming:", error, message);
        alert(`Error: ${message}`);
      },
    });
  };

  const stopStreaming = () => {
    if (webRTCAdaptorRef.current && streamId) {
      webRTCAdaptorRef.current.stop(streamId);
      setStreamId(null);
      setIsStreaming(false);
      setIsPaused(false);
    }
  };

  const pauseStreaming = () => {
    if (webRTCAdaptorRef.current && streamId) {
      webRTCAdaptorRef.current.enableVideo(false);
      webRTCAdaptorRef.current.enableAudio(false);
      setIsPaused(true);
    }
  };

  const resumeStreaming = () => {
    if (webRTCAdaptorRef.current && streamId) {
      webRTCAdaptorRef.current.enableVideo(true);
      webRTCAdaptorRef.current.enableAudio(true);
      setIsPaused(false);
    }
  };

  const toggleMinimize = (section) => {
    setMinimized((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  return (
  
  <div style={styles.container}>
    <h1 style={styles.header}>Creator Dashboard</h1>
    <div style={styles.dashboardContainer}>
  {/* Profile Section */}
  <Draggable handle=".handle">
    <Resizable
      defaultSize={{ width: 300, height: minimized.profile ? 40 : 600 }}
      minWidth={200}
      minHeight={40}
      enable={{ top: false, right: true, bottom: true, left: false }}
      style={{
        ...styles.draggableWindow,
        height: minimized.profile ? "40px" : "auto",
        backgroundColor: minimized.profile
          ? "transparent"
          : styles.profileSection.backgroundColor,
        boxShadow: minimized.profile ? "none" : styles.profileSection.boxShadow,
        borderRadius: minimized.profile ? "8px" : styles.profileSection.borderRadius,
        overflow: minimized.profile ? "hidden" : styles.profileSection.overflow,
      }}
    >
      <div>
        <div className="handle" style={styles.windowHeader}>
          Profile
          <button
            onClick={() => toggleMinimize("profile")}
            style={styles.minimizeButton}
          >
            {minimized.profile ? "Restore" : "Minimize"}
          </button>
        </div>
        {!minimized.profile && profile && (
          <div style={styles.profileSection}>
            {profile.profilePicture && (
              <img
                src={profile.profilePicture}
                alt="Profile"
                style={styles.profileImage}
              />
            )}
            <h2 style={styles.profileTitle}>{profile.username}</h2>
            <p style={styles.profileDetails}>{profile.bio}</p>
            {profile.interests && (
              <p style={styles.profileDetails}>
                Interests: {profile.interests.join(", ")}
              </p>
            )}
            {profile.hobbies && (
              <p style={styles.profileDetails}>
                Hobbies: {profile.hobbies.join(", ")}
              </p>
            )}
            {profile.age && (
              <p style={styles.profileDetails}>Age: {profile.age}</p>
            )}
            {profile.contentType && (
              <p style={styles.profileDetails}>
                Content Type: {profile.contentType}
              </p>
            )}
            <button
              onClick={handleEditProfile}
              style={styles.button}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor =
                  styles.buttonHover.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = styles.button.backgroundColor)
              }
            >
              Edit Profile
            </button>
          </div>
        )}
      </div>
    </Resizable>
  </Draggable>

  {/* Streaming Controls */}
  <Draggable handle=".handle">
    <Resizable
      defaultSize={{ width: 300, height: 300 }}
      minWidth={200}
      minHeight={100}
      enable={{ top: false, right: true, bottom: true, left: false }}
      style={styles.draggableWindow}
    >
      <div>
        <div className="handle" style={styles.windowHeader}>
          Streaming Controls
          <button
            onClick={() => toggleMinimize("stream")}
            style={styles.minimizeButton}
          >
            {minimized.stream ? "Restore" : "Minimize"}
          </button>
        </div>
        {!minimized.stream && (
          <div style={styles.streamingControls}>
            <input
              type="text"
              placeholder="Stream Title (Required)"
              value={streamTitle}
              onChange={(e) => setStreamTitle(e.target.value)}
              style={styles.input}
            />
            <textarea
              placeholder="Stream category (Required)"
              value={streamcategory}
              onChange={(e) => setStreamcategory(e.target.value)}
              style={styles.textarea}
            ></textarea>
            <input
              type="text"
              placeholder="Tags (comma-separated) (Required)"
              value={streamTags}
              onChange={(e) => setStreamTags(e.target.value)}
              style={styles.input}
            />
            <button onClick={handleViewEarnings} style={styles.button}>
              View Earnings
            </button>
            {!isStreaming ? (
              <button onClick={startStreaming} style={styles.button}>
                Start Live Stream
              </button>
            ) : (
              <>
                <button onClick={stopStreaming} style={styles.button}>
                  Stop Streaming
                </button>
                {!isPaused ? (
                  <button onClick={pauseStreaming} style={styles.button}>
                    Pause Stream
                  </button>
                ) : (
                  <button onClick={resumeStreaming} style={styles.button}>
                    Resume Stream
                  </button>
                )}
                <button
                  onClick={() => alert("Starting commercial break...")}
                  style={styles.button}
                >
                  Commercial Break
                </button>
                <button
                  onClick={() => alert("Saving stream replay...")}
                  style={styles.button}
                >
                  Save Stream as Replay
                </button>
              </>
            )}
            <video
              id="localVideo"
              ref={localVideoRef}
              autoPlay
              muted
              style={styles.video}
            ></video>
          </div>
        )}
      </div>
    </Resizable>
  </Draggable>

  {/* Analytics Section */}
  <Draggable handle=".handle">
    <Resizable
      defaultSize={{ width: 300, height: 300 }}
      minWidth={200}
      minHeight={100}
      enable={{ top: false, right: true, bottom: true, left: false }}
      style={styles.draggableWindow}
    >
      <div>
        <div className="handle" style={styles.windowHeader}>
          Analytics
          <button
            onClick={() => toggleMinimize("analytics")}
            style={styles.minimizeButton}
          >
            {minimized.analytics ? "Restore" : "Minimize"}
          </button>
        </div>
        {!minimized.analytics && (
          <div style={styles.analyticsSection}>
            <h2 style={styles.neonTitle}>Analytics</h2>
            <p style={styles.neonText}>Views: {analyticsData.views}</p>
            <p style={styles.neonText}>Followers: {analyticsData.followers}</p>
            <p style={styles.neonText}>Tips Received: ${analyticsData.tips}</p>
          </div>
        )}
      </div>
    </Resizable>
  </Draggable>

  {/* Content Section */}
  <Draggable handle=".handle">
    <Resizable
      defaultSize={{ width: 300, height: 300 }}
      minWidth={200}
      minHeight={100}
      enable={{ top: false, right: true, bottom: true, left: false }}
      style={styles.draggableWindow}
    >
      <div>
        <div className="handle" style={styles.windowHeader}>
          Your Content
          <button
            onClick={() => toggleMinimize("content")}
            style={styles.minimizeButton}
          >
            {minimized.content ? "Restore" : "Minimize"}
          </button>
        </div>
        {!minimized.content && (
          <div style={styles.contentSection}>
            <h2 style={styles.text}>Your Content</h2>
            {contentList.map((content) => (
              <div key={content._id} style={styles.contentItem}>
                <h3 style={styles.neonTitle}>{content.title}</h3>
                <p style={styles.neonText}>{content.category}</p>
                {content.filePath &&
                  (content.filePath.endsWith(".mov") ||
                  content.filePath.endsWith(".mp4") ? (
                    <video controls width="100%" style={styles.contentVideo}>
                      <source
                        src={`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/${content.filePath}`}
                        type="video/mp4"
                      />
                      <source
                        src={`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/${content.filePath}`}
                        type="video/quicktime"
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      src={`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/${content.filePath}`}
                      alt={content.title}
                      style={styles.contentImage}
                    />
                  ))}
              </div>
            ))}
            <button onClick={handleNewContent} style={styles.button}>
              Upload New Content
            </button>
          </div>
        )}
      </div>
    </Resizable>
  </Draggable>
</div>


     {/* Tipping Component Section */}
{isStreaming && (
  <Draggable handle=".handle">
    <Resizable
      defaultSize={{ width: 300, height: 200 }}
      minWidth={200}
      minHeight={100}
      enable={{ top: false, right: true, bottom: true, left: false }}
      style={styles.draggableWindow}
    >
      <div>
        <div className="handle" style={styles.windowHeader}>
          Tipping
        </div>
        <div style={styles.tippingSection}>
          <TippingComponent
            streamId={streamId}
            creatorWalletAddress={walletAddress}
            onTipSuccess={handleTipReceived}
          />
        </div>
      </div>
    </Resizable>
  </Draggable>
)}
      {/* Chat Section - Only included if streaming */}
      {isStreaming && (
        <Chat creatorWallet={walletAddress} viewerWallet={walletAddress} isCreator={true} />
      )}
    </div>
  );
}

export default CreatorDashboard;