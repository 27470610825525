// src/index.js
import React from "react";
import ReactDOM from "react-dom";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import App from "./App";
import { ThemeProvider } from './Components/ClappleOs/ThemeContext';

// Wrap App component with ThirdwebProvider to provide context to hooks
ReactDOM.render(
  
  <ThirdwebProvider activeChain="ethereum">
    <ThemeProvider>
    <App />
    </ThemeProvider>
  </ThirdwebProvider>,
  document.getElementById("root")
);