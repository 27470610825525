import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSDK, useAddress, useContract, useContractWrite } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import CampaignCreation from "../Components/Advertiser/CampaignCreation"; // Import the CampaignCreation component
import CampaignList from "../Components/Advertiser/CampaignList"; // Import the CampaignList component
import Metrics from "../Components/Advertiser/Metrics"; // Import the Metrics component

const PLATFORM_WALLET_ADDRESS = "0xf20cb9144B2Db44Dd92091d8E5454439d7dc0d92"; // Replace with your platform's wallet address
const USDC_TOKEN_ADDRESS = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"; // Replace with your USDC token contract address

function AdvertiserDashboard() {
  const [campaigns, setCampaigns] = useState([]);
  const [newCampaign, setNewCampaign] = useState({ campaignName: "", budget: "", adSlotId: "", targetUrl: "" });
  const [walletBalance, setWalletBalance] = useState(0);
  const [creativeFile, setCreativeFile] = useState(null);
  const [targetUrl, setTargetUrl] = useState("");
  const [campaignAnalytics, setCampaignAnalytics] = useState([]);

  const walletAddress = useAddress();
  const sdk = useSDK();

  // Load the USDC contract using ThirdWeb SDK
  const { contract: usdcContract } = useContract(USDC_TOKEN_ADDRESS, "token");
  const { mutateAsync: transferTokens } = useContractWrite(usdcContract, "transfer");

  useEffect(() => {
    if (!walletAddress) {
      console.log("Wallet not connected.");
      return;
    }

    console.log("Connected wallet address:", walletAddress);

    const fetchCampaigns = async () => {
      try {
        const response = await axios.get(`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/advertisers/${walletAddress}/campaigns`);
        if (response.data && Array.isArray(response.data)) {
          setCampaigns(response.data);
        } else if (response.data && response.data.campaigns) {
          setCampaigns(response.data.campaigns);
        } else {
          setCampaigns([]);
        }
      } catch (error) {
        console.error("Error fetching campaigns:", error.message);
      }
    };

    const fetchWalletBalance = async () => {
      try {
        const response = await axios.get(`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/advertisers/wallet-balance?walletAddress=${walletAddress}`);
        if (response.data && typeof response.data.balance === "number") {
          setWalletBalance(response.data.balance);
        }
      } catch (error) {
        console.error("Error fetching wallet balance:", error.message);
      }
    };

    const fetchCampaignAnalytics = async () => {
      try {
        const response = await axios.get(`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/advertisers/${walletAddress}/campaigns/analytics`);
        if (response.data && Array.isArray(response.data.analytics)) {
          setCampaignAnalytics(response.data.analytics);
        } else {
          setCampaignAnalytics([]);
        }
      } catch (error) {
        console.error("Error fetching campaign analytics:", error.message);
      }
    };

    fetchCampaigns();
    fetchWalletBalance();
    fetchCampaignAnalytics();
  }, [walletAddress]);

  const handleAddFunds = async (amount) => {
    if (!walletAddress) {
      console.error("No wallet address found. Connect your wallet first.");
      alert("Please connect your wallet before adding funds.");
      return;
    }

    try {
      // Get the USDC token contract and initiate transfer
      console.log("Attempting to transfer USDC from wallet:", walletAddress);

      // Execute wallet transaction to send USDC to platform wallet
      const transferResponse = await transferTokens({
        args: [PLATFORM_WALLET_ADDRESS, ethers.utils.parseUnits(amount.toString(), 6)], // USDC typically has 6 decimals
      });

      console.log("Transfer response:", transferResponse);

      // After transaction, update wallet balance in backend
      const response = await axios.post("https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/advertisers/add-funds", {
        walletAddress,
        amount,
      });

      if (response.data && typeof response.data.newBalance === "number") {
        setWalletBalance(response.data.newBalance);
      }

      alert("Funds added successfully!");
    } catch (error) {
      console.error("Error adding funds:", error.message);
      alert("Transaction failed or was rejected.");
    }
  };

  return (
    <div>
      <h1>Advertiser Dashboard</h1>
      <section>
        <h2>Wallet Balance</h2>
        <p>Current Balance: {walletBalance} USDC</p>
        <button onClick={() => handleAddFunds(100)}>Add 100 USDC</button>
      </section>

      <section>
        <CampaignCreation
          newCampaign={newCampaign}
          setNewCampaign={setNewCampaign}
          walletAddress={walletAddress}
        />
      </section>

      <section>
        <CampaignList campaigns={campaigns} setCreativeFile={setCreativeFile} setTargetUrl={setTargetUrl} />
      </section>

      <section>
        <Metrics campaignAnalytics={campaignAnalytics} />
      </section>
    </div>
  );
}

export default AdvertiserDashboard;