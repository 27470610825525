import React, { useState } from 'react';
import axios from 'axios';
import { useAddress } from '@thirdweb-dev/react';

const CampaignCreation = ({ onCampaignCreated }) => {
  const [campaignName, setCampaignName] = useState('');
  const [dailyBudget, setDailyBudget] = useState('');
  const [cpcBid, setCpcBid] = useState('');
  const [adSlotId, setAdSlotId] = useState('');
  const [adCreativeUrl, setAdCreativeUrl] = useState('');
  const [targetUrl, setTargetUrl] = useState('');

  const walletAddress = useAddress();

  const handleCreateCampaign = async () => {
    if (!walletAddress) {
      alert('Please connect your wallet before creating a campaign.');
      return;
    }

    if (!campaignName || !dailyBudget || !cpcBid || !adSlotId || !adCreativeUrl || !targetUrl) {
      alert('Please fill in all required fields.');
      return;
    }

    if (parseFloat(dailyBudget) <= 0 || parseFloat(cpcBid) <= 0) {
      alert('Daily budget and CPC bid must be greater than zero.');
      return;
    }

    if (!isValidURL(adCreativeUrl) || !isValidURL(targetUrl)) {
      alert('Please enter valid URLs for the Ad Creative and Target URL.');
      return;
    }

    const campaignData = {
      campaignName,
      dailyBudget: parseFloat(dailyBudget),
      cpcBid: parseFloat(cpcBid),
      adSlotId,
      adCreativeUrl,
      targetUrl,
    };

    try {
      console.log("Sending campaign data:", campaignData);

      const response = await axios.post(
        `https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/advertisers/${walletAddress}/campaigns`,
        campaignData
      );

      if (response.status === 201) {
        alert('Campaign created successfully!');
        onCampaignCreated();

        // Reset the form fields
        setCampaignName('');
        setDailyBudget('');
        setCpcBid('');
        setAdSlotId('');
        setAdCreativeUrl('');
        setTargetUrl('');
      } else {
        console.error('Unexpected response status:', response.status);
        alert('Unexpected response when creating campaign. Please try again.');
      }
    } catch (error) {
      if (error.response) {
        console.error('Error response data:', error.response.data);
        alert(`Failed to create campaign: ${error.response.data.message}`);
      } else {
        console.error('Error creating campaign:', error.message);
        alert('Failed to create campaign. Please try again.');
      }
    }
  };

  const isValidURL = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <div className="campaign-creation">
      <h3>Create a New Campaign</h3>
      <input
        type="text"
        placeholder="Campaign Name"
        value={campaignName}
        onChange={(e) => setCampaignName(e.target.value)}
        required
      />
      <input
        type="number"
        placeholder="Daily Budget (in USDC)"
        value={dailyBudget}
        onChange={(e) => setDailyBudget(e.target.value)}
        required
      />
      <input
        type="number"
        placeholder="Cost Per Click Bid (in USDC)"
        value={cpcBid}
        onChange={(e) => setCpcBid(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Ad Slot ID"
        value={adSlotId}
        onChange={(e) => setAdSlotId(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Ad Creative URL"
        value={adCreativeUrl}
        onChange={(e) => setAdCreativeUrl(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Target URL"
        value={targetUrl}
        onChange={(e) => setTargetUrl(e.target.value)}
        required
      />
      <button onClick={handleCreateCampaign}>Create Campaign</button>
    </div>
  );
};

export default CampaignCreation;