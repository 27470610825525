// src/components/DesktopBackground.js
import React from 'react';
import { Parallax } from 'react-parallax';
import './DesktopBackground.css';
import backgroundImage from './BLOWOS-BG.png'; // Adjust the path to your image

const DesktopBackground = ({ children }) => {
  return (
    <Parallax bgImage={backgroundImage} strength={200}>
      <div className="desktop-background">
        {children}
      </div>
    </Parallax>
  );
};

export default DesktopBackground;