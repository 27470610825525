import React from "react";

const StakeNFT = ({ height = "100%", width = "100%" }) => {
  return (
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <iframe
        src="https://whorehouse.nosecandy.io"
        title="WhoreHouse"
        style={{
          border: "none",
          width: width,
          height: height,
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default StakeNFT;