// src/components/TipCalculator.js

import React, { useState } from "react";
import NoseCandyPrice from "../NoseCandyPrice";
import HarryPotterObamaSonicPrice from "../HarryPotterObamaSonicPrice";

const TipCalculator = () => {
  const [selectedToken, setSelectedToken] = useState("nosecandy");
  const [usdAmount, setUsdAmount] = useState(0);
  const noseCandyPrice = NoseCandyPrice();
  const harryPotterObamaSonicPrice = HarryPotterObamaSonicPrice();

  // Get the price of the selected token
  const currentPrice =
    selectedToken === "nosecandy" ? noseCandyPrice : harryPotterObamaSonicPrice;

  // Calculate token amount based on USD input and token price
  const tokenAmount = currentPrice ? (usdAmount / currentPrice).toFixed(6) : 0;

  // Update the selected token
  const handleTokenChange = (e) => setSelectedToken(e.target.value);

  // Update the USD amount
  const handleUsdChange = (e) => setUsdAmount(e.target.value);

  return (
    <div style={styles.container}>
      <h3>Select Token for Tipping</h3>
      <select value={selectedToken} onChange={handleTokenChange} style={styles.select}>
        <option value="nosecandy">NoseCandy</option>
        <option value="harrypotterobamasonic">HarryPotterObamaSonic10Inu</option>
      </select>

      <div style={styles.inputContainer}>
        <label>Enter USD Amount:</label>
        <input
          type="number"
          value={usdAmount}
          onChange={handleUsdChange}
          style={styles.input}
          placeholder="Enter USD amount"
        />
      </div>

      <p>Current Price: {currentPrice ? `$${currentPrice} USD` : "Loading..."}</p>
      <p>Equivalent Token Amount: {tokenAmount} {selectedToken === "nosecandy" ? "NoseCandy" : "HarryPotterObamaSonic10Inu"}</p>
    </div>
  );
};

// Styling for the calculator
const styles = {
  container: {
    padding: '20px',
    backgroundColor: 'rgba(30, 30, 30, 0.9)',
    borderRadius: '12px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
    textAlign: 'center',
    color: 'white'
  },
  select: {
    marginBottom: '15px',
    padding: '8px',
    borderRadius: '8px',
    width: '100%',
  },
  inputContainer: {
    margin: '15px 0',
  },
  input: {
    width: '100%',
    padding: '8px',
    borderRadius: '8px',
    textAlign: 'center',
  }
};

export default TipCalculator;