import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAddress } from '@thirdweb-dev/react';

const CampaignList = () => {
  const [campaigns, setCampaigns] = useState([]);
  const walletAddress = useAddress();

  useEffect(() => {
    const fetchCampaigns = async () => {
      if (!walletAddress) {
        return; // Don't fetch if the wallet is not connected
      }

      try {
        const response = await axios.get(`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/advertisers/${walletAddress}/campaigns`);
        
        if (response.data && response.data.campaigns) {
          setCampaigns(response.data.campaigns);
        } else {
          console.error('No campaigns found in response:', response.data);
        }
      } catch (error) {
        console.error('Error fetching campaigns:', error.message);
      }
    };

    fetchCampaigns();
  }, [walletAddress]);

  if (!walletAddress) {
    return <div>Please connect your wallet to see your campaigns.</div>;
  }

  return (
    <div className="campaign-list">
      <h3>Your Campaigns</h3>
      {campaigns.length === 0 ? (
        <p>No campaigns found.</p>
      ) : (
        <ul>
          {campaigns.map((campaign) => (
            <li key={campaign._id}>
              <strong>{campaign.campaignName}</strong> - Daily Budget: {campaign.dailyBudget}, Clicks: {campaign.clicks}, Impressions: {campaign.impressions}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CampaignList;