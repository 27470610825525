const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100vw",
    fontFamily: "Arial, sans-serif",
    maxWidth: "1200px",
    margin: "20px auto",
    padding: "20px",
    borderRadius: "12px",
    color: "#333", // Subtle dark gray text
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Light outer shadow
  },
  leftSidebar: {
    width: "20%",
    backgroundColor: "#ebebed", // Light gray with a soft touch
    padding: "15px",
    borderRadius: "12px",
    overflowY: "auto",
    maxHeight: "80vh",
    boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)", // Soft inner shadow
  },
  rightSidebar: {
    width: "20%",
    backgroundColor: "#ebebed", // Match left sidebar
    padding: "15px",
    borderRadius: "12px",
    overflowY: "auto",
    maxHeight: "80vh",
    boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)", // Soft inner shadow
  },
  leftSidebarMinimized: {
    width: "3%",
    backgroundColor: "#d1d1d3", // Even lighter gray for minimized state
  },
  rightSidebarMinimized: {
    width: "3%",
    backgroundColor: "#d1d1d3",
  },
  profileSection: {
    width: "20%",
    backgroundColor: "#f9f9f9", // Soft off-white
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", // Subtle elevated shadow
    transition: "all 0.3s ease",
  },
  profileSectionMinimized: {
    width: "25%",
    backgroundColor: "transparent",
    padding: "5px",
    boxShadow: "none",
  },
  windowHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  profileImage: {
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    objectFit: "cover",
    marginBottom: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
  },
  bioText: {
    fontSize: "1em",
    color: "#555", // Subtle medium gray
    margin: "10px 0",
  },
  searchBar: {
    padding: "10px",
    width: "100%",
    marginBottom: "20px",
    fontSize: "15px",
    borderRadius: "8px",
    border: "1px solid #ccc",
    backgroundColor: "#f2f2f4", // Light gray background
    color: "#333",
    boxShadow: "inset 0 1px 2px rgba(0, 0, 0, 0.2)",
  },
  streamsGrid: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
  },
  ard: {
    width: "280px",
    backgroundColor: "#ffffff", // Pure white for cards
    padding: "15px",
    borderRadius: "12px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
    textAlign: "center",
    color: "#333",
  },
  thumbnail: {
    width: "100%",
    borderRadius: "8px",
    marginBottom: "10px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
  },
  streamTitle: {
    fontSize: "1.1em",
    margin: "10px 0",
    color: "#007aff", // macOS accent blue
  },
  creatorText: {
    fontSize: "0.9em",
    marginBottom: "10px",
    color: "#666", // Medium gray for text
  },
  button: {
    backgroundColor: "#007aff", // macOS accent blue
    color: "#ffffff",
    padding: "10px 20px",
    borderRadius: "8px",
    cursor: "pointer",
    border: "none",
    marginBottom: "10px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.2s, transform 0.1s",
  },
  followButton: {
    backgroundColor: "#4cd964", // macOS green
    color: "#ffffff",
    padding: "8px 20px",
    borderRadius: "8px",
    cursor: "pointer",
    border: "none",
    marginBottom: "10px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
  },
  noStreamsText: {
    textAlign: "center",
    fontSize: "1em",
    color: "#999", // Lighter gray
  },
  creatorsList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
  },
  creatorCard: {
    width: "200px",
    backgroundColor: "#ffffff", // Pure white for contrast
    padding: "15px",
    borderRadius: "12px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    color: "#007aff", // Accent blue
  },
  creatorLink: {
    color: "#007aff",
    cursor: "pointer",
    textDecoration: "none",
    fontSize: "1.1em",
    textShadow: "0 1px 2px rgba(0, 0, 0, 0.3)",
  },
  streamEmbed: {
    marginTop: "20px",
    textAlign: "center",
  },
  iframe: {
    width: "100%",
    height: "500px",
    borderRadius: "8px",
    border: "none",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
  },
  tipCalculatorContainer: {
    marginTop: "20px",
    padding: "15px",
    backgroundColor: "#ffffff", // White for inputs and forms
    borderRadius: "12px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
  },
  neonTitle: {
    fontSize: "1.3em",
    color: "#007aff", // macOS blue
    fontWeight: "600",
    textShadow: "0 0 4px rgba(0, 122, 255, 0.4)",
    marginBottom: "10px",
  },
  dashboardContainer: {
    position: "relative",
    width: "100vw",
    height: "100vh",
    overflow: "visible",
    backgroundColor: "#f5f5f7", // macOS light gray background
  },
  minimizeButton: {
    backgroundColor: "#e0e0e0",
    color: "#555",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    padding: "3px 8px",
    fontSize: "0.8em",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.2s",
  },
  nowWatching: {
    backgroundColor: "#f9f9f9", // macOS-like light gray background
    border: "1px solid #e0e0e0", // Subtle border
    borderRadius: "12px", // Rounded corners
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Soft shadow
    padding: "20px", // Space inside the section
    margin: "20px 0", // Space around the section
    color: "#333", // Neutral dark text
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif", // macOS font stack
    textAlign: "left", // Align content to the left
    height: "calc(100vh - 140px)", // Dynamically calculate height to fill the screen
    overflowY: "auto", // Enable vertical scrolling
    scrollbarWidth: "thin", // For Firefox: thin scrollbar
    scrollbarColor: "#cccccc #f9f9f9", // Customize scrollbar color
  },
  nowWatchingHeader: {
    fontSize: "1.5rem", // Larger font for the header
    fontWeight: "600", // Semi-bold for emphasis
    color: "#007aff", // macOS accent blue
    marginBottom: "15px", // Space below the header
  },
  nowWatchingList: {
    listStyle: "none", // Remove bullet points
    padding: 0, // Remove padding
    margin: 0, // Remove margin
  },
  nowWatchingItem: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffffff", // White background for contrast
    borderRadius: "8px", // Rounded corners for each item
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
    padding: "10px", // Space inside each item
    marginBottom: "15px", // Space between items
    transition: "background-color 0.2s ease, box-shadow 0.2s ease", // Smooth hover effect
  },
  nowWatchingThumbnail: {
    width: "50px", // Fixed size for thumbnails
    height: "50px",
    borderRadius: "8px", // Rounded corners for thumbnails
    marginRight: "15px", // Space between thumbnail and text
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
  },
  nowWatchingDetails: {
    flexGrow: 1, // Allow details to take up remaining space
  },
  nowWatchingTitle: {
    fontSize: "1rem", // Regular font size for titles
    fontWeight: "500", // Medium weight
    color: "#333", // Neutral dark text
    margin: 0, // Remove default margin
  },
  nowWatchingSubtitle: {
    fontSize: "0.9rem", // Slightly smaller font size for subtitles
    color: "#666", // Subtle gray text
  },
  nowWatchingControls: {
    display: "flex",
    gap: "10px", // Space between buttons
  },
  controlButton: {
    backgroundColor: "#007aff", // macOS accent blue
    color: "#ffffff",
    padding: "8px 12px", // Button padding
    border: "none",
    borderRadius: "6px", // Rounded corners
    fontSize: "0.85rem", // Slightly smaller text
    cursor: "pointer",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
    transition: "background-color 0.2s ease, transform 0.1s ease", // Smooth hover effect
  },
};


export default styles;