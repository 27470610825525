import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TokenGate from "../Components/TokenGate";
import './CreateViewerProfile.css'; // Import the new CSS file

function CreateViewerProfile({ walletAddress }) {
  const navigate = useNavigate();

  const [profile, setProfile] = useState({
    username: "",
    bio: "",
    interests: "",
    hobbies: "",
    age: "",
    profilePicture: null, // Store file object here to send to backend
  });
  const [isLoading, setIsLoading] = useState(true);
  const [profileExists, setProfileExists] = useState(false);
  const [usernameError, setUsernameError] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/viewers`, {
          params: { wallet: walletAddress },
        });
        if (response.data) {
          setProfile({
            ...response.data,
            interests: response.data.interests ? response.data.interests.join(", ") : "",
            hobbies: response.data.hobbies ? response.data.hobbies.join(", ") : "",
          });
          setProfileExists(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setProfileExists(false);
          console.log("No viewer profile found for this wallet.");
        } else {
          console.error("Error fetching viewer profile:", error);
        }
      } finally {
        setIsLoading(false);
      }
    };
    if (walletAddress) {
      fetchProfile();
    }
  }, [walletAddress]);

  // Updates the local profile with the selected file
  const handleFileChange = (e) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      profilePicture: e.target.files[0], // Save file object directly
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare FormData for backend to handle file upload
    const formData = new FormData();
    formData.append("username", profile.username);
    formData.append("bio", profile.bio);
    formData.append("interests", profile.interests);
    formData.append("hobbies", profile.hobbies);
    formData.append("age", profile.age);
    formData.append("wallet", walletAddress);
    formData.append("role", "viewer");
    if (profile.profilePicture) {
      formData.append("profilePicture", profile.profilePicture); // Add image file for backend handling
    }

    try {
      if (profileExists) {
        await axios.patch(
          `https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/viewers/${encodeURIComponent(walletAddress)}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        alert("Profile updated successfully.");
        navigate("/viewer-dashboard");
      } else {
        const usernameCheck = await axios.get(`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/viewers/check-username`, {
          params: { username: profile.username },
        });

        if (usernameCheck.data.exists) {
          setUsernameError("Username already taken. Please choose another one.");
          return;
        }

        await axios.post(`https://dushbunnybe-d9e7ead8a6f6.herokuapp.com/api/viewers`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        alert("Profile created successfully.");
        setProfileExists(true);
        navigate("/viewer-dashboard");
      }
    } catch (error) {
      console.error("Error submitting profile:", error);
      alert(`Error submitting profile: ${error.response?.data?.message || error.message}`);
    }
  };

  if (isLoading) return <p className="loading">Loading...</p>;

  return (
    <TokenGate>
      <div className="container">
        <h1 className="header">
          {profileExists ? "Edit Your Viewer Profile" : "Create Your Viewer Profile"}
        </h1>
        <form onSubmit={handleSubmit} className="form">
          <label className="label">
            Username:
            <input
              type="text"
              name="username"
              value={profile.username || ""}
              onChange={(e) => setProfile({ ...profile, username: e.target.value })}
              className="input"
              required
            />
            {usernameError && <p className="error">{usernameError}</p>}
          </label>
          <label className="label">
            Bio:
            <textarea
              name="bio"
              value={profile.bio || ""}
              onChange={(e) => setProfile({ ...profile, bio: e.target.value })}
              className="textarea"
              required
            />
          </label>
          <label className="label">
            Interests (comma-separated):
            <input
              type="text"
              name="interests"
              value={profile.interests || ""}
              onChange={(e) => setProfile({ ...profile, interests: e.target.value })}
              className="input"
            />
          </label>
          <label className="label">
            Hobbies (comma-separated):
            <input
              type="text"
              name="hobbies"
              value={profile.hobbies || ""}
              onChange={(e) => setProfile({ ...profile, hobbies: e.target.value })}
              className="input"
            />
          </label>
          <label className="label">
            Age:
            <input
              type="number"
              name="age"
              value={profile.age || ""}
              onChange={(e) => setProfile({ ...profile, age: e.target.value })}
              className="input"
            />
          </label>
          <label className="label">
            Profile Picture:
            <input
              type="file"
              name="profilePicture"
              onChange={handleFileChange}
              className="input"
            />
          </label>
          <button type="submit" className="button">
            {profileExists ? "Update Profile" : "Create Profile"}
          </button>
        </form>
        {profile.profilePicture && typeof profile.profilePicture === "string" && (
          <div>
            <h3 className="preview-header">Preview:</h3>
            <img
              src={profile.profilePicture}
              alt="Profile"
              className="image-preview"
            />
          </div>
        )}
      </div>
    </TokenGate>
  );
}

export default CreateViewerProfile;